import { cookieAuthInstance } from "lib/api/utils";

const listActivities = () => {
  return cookieAuthInstance.get<Array<DBCourseActivity>>(
    `/teachers/activities/list`,
  );
};

const listCourses = () => {
  return cookieAuthInstance.get<Array<DBCourse>>(`/teachers/courses/list`);
};

const teachers = {
  listActivities,
  listCourses,
};

export default teachers;
