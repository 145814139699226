import { cookieAuthInstance } from "lib/api/utils";
const mediaUpload = ({
  schoolId,
  formData,
}: {
  schoolId: string;
  formData: FormData;
}) => {
  return cookieAuthInstance.post<UploadMediaFilesResponse>(
    `/media/?school_id=${schoolId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
};
const media = {
  upload: mediaUpload,
};

export default media;
