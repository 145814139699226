import { cookieAuthInstance } from "lib/api/utils";

const schoolCreate = ({
  name,
  address,
  city,
  state,
  zipCode,
  contactEmail,
  contactPhoneNumber,
  planId,
  orgType,
}: SchoolCreateRequest) => {
  return cookieAuthInstance.post<SchoolResponse>(`/schools/`, {
    name,
    address,
    city,
    state,
    zipCode,
    contactEmail,
    contactPhoneNumber,
    planId,
    orgType,
  });
};

const schoolList = async ({ page = 1, rowsPerPage = 10 }) => {
  try {
    const response = await cookieAuthInstance.get<SchoolsResponse>(
      `/schools/?page=${page}&per_page=${rowsPerPage}`,
    );
    return response;
  } catch (error: any) {
    console.error("Error in schoolList:", error);
    throw error;
  }
};

const schoolGet = ({ schoolId }: { schoolId: string }) => {
  return cookieAuthInstance.get<SchoolResponse>(`/schools/${schoolId}`);
};

const schoolUpdate = ({
  schoolId,
  name,
  address,
  city,
  state,
  zipCode,
  contactEmail,
  contactPhoneNumber,
  orgType,
}: SchoolUpdateRequest & { schoolId: string }) => {
  return cookieAuthInstance.patch<SchoolResponse>(`/schools/${schoolId}`, {
    name,
    address,
    city,
    state,
    zipCode,
    contactEmail,
    contactPhoneNumber,
    orgType,
  });
};

const schoolGenerateCode = ({ schoolId }: { schoolId: string }) => {
  return cookieAuthInstance.post<SchoolWithCodeResponse>(
    `/schools/${schoolId}/code/`,
  );
};

const schoolListCodes = ({ schoolId }: { schoolId: string }) => {
  return cookieAuthInstance.get<SchoolWithCodesResponse>(
    `/schools/${schoolId}/code/`,
  );
};

const classroomList = ({
  schoolId,
  page = 1,
  rowsPerPage = 10,
  teachersPerPage = 10,
  pupilsPerPage = 10,
  mine,
}: {
  schoolId: string;
  page?: number;
  rowsPerPage?: number;
  teachersPerPage?: number;
  pupilsPerPage?: number;
  mine?: boolean;
}) => {
  return cookieAuthInstance.get<ClassroomsResponse>(
    `/schools/${schoolId}/classrooms/?page=${page}&per_page=${rowsPerPage}&per_page_pupils=${pupilsPerPage}&per_page_teachers=${teachersPerPage}${
      mine ? "&mine=True" : ""
    }`,
  );
};

const school = {
  create: schoolCreate,
  list: schoolList,
  get: schoolGet,
  update: schoolUpdate,
  generateCode: schoolGenerateCode,
  listCodes: schoolListCodes,
  listClassrooms: classroomList,
};

export default school;
