import React from 'react';
import { Box, Typography, CircularProgress } from "@mui/material";
import ChatInput from "./ChatInput";
import MessageComponent from "./MessageComponent";
import RecommendedChatOptions from "./RecommendedChatOptions";
import { connect } from "react-redux";
import { setSnackbar } from "reducers/ui";
import { setSelectedActivity, setFlaggedCourses } from "reducers/user";

const ChatArea = ({
  hasStartedChat,
  selectedActivity,
  messageBoxRef,
  chatHistory,
  currentMessage,
  sendMessage,
  chatLoading,
  moreLoading,
}: {
  hasStartedChat: boolean;
  selectedActivity: any;
  messageBoxRef: React.MutableRefObject<Element | null | undefined>;
  chatHistory: Array<any>;
  currentMessage: string;
  sendMessage: (message: string) => void;
  chatLoading: boolean;
  moreLoading: boolean;
}) => {

  // add a loading state check for selectedActivity
  if (!selectedActivity?.activity) {
    return (
      <Box sx={{
        height: "72vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ position: "relative", height: "100%", width: "100%", mt: 4 }}>
      {hasStartedChat && selectedActivity.activity.enabled ? (
        <>
          <Box
            ref={messageBoxRef}
            sx={{
              width: "100%",
              height: "72vh",
              overflowY: "scroll",
              position: "relative",
              display: "flex",
              flexDirection: "column-reverse",
              pt: 2,
              opacity: chatLoading ? 0.7 : 1,
              transition: "opacity 0.2s ease-in-out"
            }}
          >
            {chatHistory.map((chat, index) =>
              currentMessage.length > 0 && index === 0 ? (
                <MessageComponent
                  key={`${index}-student-message`}
                  message={currentMessage}
                  isReceived={true}
                />
              ) : (
                <MessageComponent
                  key={index}
                  message={chat.message}
                  isReceived={chat.sender === "system"}
                />
              ),
            )}
          </Box>
          <Box sx={{ width: "100%", p: 2, pb: 0 }}>
            <ChatInput onSendMessage={sendMessage} isLoading={chatLoading} />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            height: "72vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            borderRadius: 2,
            position: "relative",
            overflow: "hidden",
            mt: 2,
            opacity: 0,
            animation: "fadeIn 0.3s ease-in-out forwards",
            "@keyframes fadeIn": {
              from: { opacity: 0 },
              to: { opacity: 1 }
            }
          }}
        >
          <Box sx={{ position: "relative", zIndex: 2 }}>
            {selectedActivity?.activity.enabled ? (
              <RecommendedChatOptions onSelectOption={sendMessage} />
            ) : (
              <Typography
                fontFamily="Inter"
                sx={{
                  opacity: 0,
                  animation: "fadeIn 0.3s ease-in-out 0.2s forwards"
                }}
              >
                This activity is disabled. Please wait for your instructor to
                enable the activity to begin.
              </Typography>
            )}
          </Box>
        </Box>
      )}
      {moreLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            pt: 2,
            zIndex: 1,
          }}
        >
          <CircularProgress sx={{ ml: 1 }} />
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  userId: state.user.id,
  token: state.user.token,
  selectedCourse: state.user.selectedCourse,
  selectedActivity: state.user.selectedActivity,
  currentSchoolId: state.user.currentSchoolId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
  flaggedCourses: state.user.flaggedCourses,
});

const mapDispatchToProps = {
  setSnackbar,
  setSelectedActivity,
  setFlaggedCourses,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatArea);