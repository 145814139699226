import MessageComponent from "components/student/MessageComponent";
import { Box, IconButton, Typography } from "@mui/material";
import { connect, useSelector, useDispatch } from "react-redux";
import { Close, Fullscreen } from "@mui/icons-material";
import { showStudentConversation } from "actions/studentConversation";
import { useState } from "react";

function StudentChatPreview({ studentChatHistory, height }: any) {
  const studentConversation = useSelector(
    (state: any) => state.studentConversation,
  );

  const dispatch = useDispatch();

  const [fullscreen, setFullScreen] = useState(false);

  const handleCloseClick = () => {
    dispatch(showStudentConversation(null));
  };
  return (
    <Box
      sx={{
        borderRadius: 4,
        height,
        backgroundColor: "inputs.light",
        paddingBottom: "2rem",
        flexGrow: 1,
        ...(fullscreen && {
          position: "absolute",
          zIndex: 1,
          left: "50%",
          transform: "translate(-50%, -0%)",
          width: "calc(100vw - 1rem)",
        }),
      }}
    >
      <Box
        height="2rem"
        display="flex"
        flexDirection="row-reverse"
        sx={{
          paddingX: "0.5rem",
          paddingTop: "0.3rem",
        }}
      >
        <IconButton
          onClick={handleCloseClick}
          title="Close student chat preview"
          sx={{
            padding: "4px",
            width: "32px",
            height: "32px",
            "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
          }}
        >
          <Close />
        </IconButton>
        <IconButton
          onClick={() => setFullScreen(!fullscreen)}
          title="Make student chat preview fullscreen."
          sx={{
            padding: "4px",
            width: "32px",
            height: "32px",
            "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
          }}
        >
          <Fullscreen />
        </IconButton>
      </Box>
      <Typography
        fontFamily="Inter"
        fontSize={18}
        fontWeight={500}
        sx={{
          paddingX: "1.25rem",
          marginTop: "-1.5rem",
        }}
      >
        {studentConversation.studentConversation.name}'s Chat
      </Typography>

      <Box
        sx={{
          overflowY: "scroll",
          height: "92%",
          paddingX: "1rem",
          marginTop: "0.5rem",
        }}
      >
        {studentChatHistory.map((chat: any, index: any) => {
          return (
            <MessageComponent
              key={index}
              message={chat.message}
              isReceived={chat.sender === "system"}
              timeStamp={chat.updatedAt}
            />
          );
        })}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: any) => ({
  selectedActivity: state.user.selectedActivity,
  user: state.user.currentSchoolId,
  currentSchoolCode: state.user.currentSchoolCode,
  currentClassroomId: state.user.currentClassroomId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
  currentStudentId: state.user.id,
});

const mapDispatchToProps = {
  showStudentConversation,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentChatPreview);
