import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { setSnackbar } from "reducers/ui";
import api from "lib/api";
import { Box, CircularProgress } from '@mui/material';

interface EmbeddedDashboardProps {
  dashboardId: number;
  courseId: string;
  setSnackbar: (snackbar: { open: boolean; severity: string; message: string }) => void;
}

const EmbeddedDashboard: React.FC<EmbeddedDashboardProps> = ({ dashboardId, courseId, setSnackbar }) => {
  const [embedUrl, setEmbedUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [iframeHeight, setIframeHeight] = useState('2000px');
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchEmbedUrl = async () => {
      if (!courseId) {
        setError('Course ID is required');
        return;
      }

      try {
        const response = await api.metabase.getCourseEmbedUrl(dashboardId, courseId);
        setEmbedUrl(response.data.embed_url);
      } catch (error: any) {
        const message = error.response?.data?.detail || error.message || 'Failed to fetch embed URL';
        setError(message);
        setSnackbar({
          open: true,
          severity: "error",
          message,
        });
      }
    };

    fetchEmbedUrl();
  }, [dashboardId, courseId, setSnackbar]);

  const handleIframeLoad = () => {
    if (iframeRef.current) {
      try {
        const height = iframeRef.current.contentWindow?.document.body.scrollHeight;
        if (height) {
          setIframeHeight(`${height + 50}px`);
        }
      } catch (e) {
        console.log('Could not determine iframe content height');
      }
    }
  };

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: 4,
          color: 'error.main'
        }}
      >
        Error: {error}
      </Box>
    );
  }

  if (!embedUrl) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: 4
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      ref={containerRef}
      sx={{
        width: '80%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        p: 4,
        overflow: 'auto'
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '1400px',
          mx: 'auto',
          position: 'relative'
        }}
      >
        <iframe
          ref={iframeRef}
          src={embedUrl}
          frameBorder="0"
          style={{
            width: '100%',
            height: iframeHeight,
            border: 'none',
          }}
          onLoad={handleIframeLoad}
          allowTransparency
        />
      </Box>
    </Box>
  );
};

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(null, mapDispatchToProps)(EmbeddedDashboard);