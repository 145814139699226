const findMaxEmotion: (
  student: any,
) =>
  | "None"
  | "Engaged"
  | "Excited"
  | "Curious"
  | "Confused"
  | "Frustrated"
  | "Bored" = (student: any) => {
  if (student.sentiment_analytics && student.sentiment_analytics[0]) {
    let max_val = 0;
    const sentiments = student.sentiment_analytics[0];
    let sentiment:
      | "None"
      | "Engaged"
      | "Excited"
      | "Curious"
      | "Confused"
      | "Frustrated"
      | "Bored" = "None";
    if (sentiments.focused > max_val) {
      max_val = sentiments.focused;
      sentiment = "Engaged";
    }
    if (sentiments.excitement > max_val) {
      max_val = sentiments.excitement;
      sentiment = "Excited";
    }
    if (sentiments.curiosity > max_val) {
      max_val = sentiments.curiosity;
      sentiment = "Curious";
    }
    if (sentiments.confusion > max_val) {
      max_val = sentiments.confusion;
      sentiment = "Confused";
    }
    if (sentiments.frustration > max_val) {
      max_val = sentiments.frustration;
      sentiment = "Frustrated";
    }
    if (sentiments.boredom > max_val) {
      max_val = sentiments.boredom;
      sentiment = "Bored";
    }
    return sentiment;
  } else {
    return "None";
  }
};

export default findMaxEmotion;
