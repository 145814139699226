import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import api from "lib/api";
import { useAuth0 } from "@auth0/auth0-react";

const useCourses = (props: any) => {
  const { userId, role, currentSchoolId } = props;
  const history = useHistory();
  const [courses, setCourses] = useState([] as Array<any>);
  const { getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    const fetchCourses = async (classroomTeacherId: string) => {
      try {
        console.log("Making call to fetch courses.");
        if (!classroomTeacherId)
          throw new Error(
            "Unable to fetch courses. Expected classroom id and got none",
          );
        const response = await api.classroom.listCourses({
          classroomTeacherId,
        });

        return response.data.courses;
      } catch (e: any) {
        props.setSnackbar({
          open: true,
          message: e.message,
          severity: "error",
        });
      }
    };

    const fetchClassrooms = async () => {
      props.setLoading(true);
      try {
        console.log("Making call to fetch classrooms.");
        const response = await api.school.listClassrooms({
          schoolId: currentSchoolId,
          rowsPerPage: 999,
          teachersPerPage: 99999,
          pupilsPerPage: 99999,
          mine: role === "teacher",
        });
        const classrooms = response.data.classrooms;

        if (classrooms.length > 0) {
          let allCourses = await Promise.all(
            classrooms
              .filter((classroom: any) =>
                role === "teacher"
                  ? classroom.teachers?.find(
                      (teacher: any) => teacher.id === userId,
                    )?.classroomTeacherId
                  : classroom.pupils.find((pupil: any) => pupil.id === userId),
              )
              .map(async (classroom: any) => {
                const filteredCourses = await fetchCourses(
                  role === "teacher"
                    ? classroom.teachers?.find((t: any) => t.id === userId)
                        ?.classroomTeacherId
                    : classroom.teachers[0].classroomTeacherId,
                );

                if (!filteredCourses) return;

                return filteredCourses?.map((course: any) => ({
                  ...course.course,
                  classroomId: classroom.classroom.id,
                  materials: course.materials,
                }));
              }),
          );

          allCourses = allCourses.flat(1).filter((c: any) => c);

          if (allCourses.length > 0) setCourses(allCourses);
          else history.push("/classes");
        } else history.push("/classes");
      } catch (e: any) {
        props.setSnackbar({
          open: true,
          message: e.message,
          severity: "error",
        });
      } finally {
        props.setLoading(false);
      }
    };

    if (currentSchoolId) fetchClassrooms();

    props.setSelectedCourse();
    props.setSelectedActivity();
    props.setCurrentClassroomTeacherId();
    props.setCurrentClassroomId();
    // eslint-disable-next-line
  }, [currentSchoolId, getAccessTokenSilently, user]);

  return courses;
};

export { useCourses };
