import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

const Insight = (props: any) => {
  const [insightsOpen, setInsightsOpen] = useState(true);

  return (
    insightsOpen && (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "primary.weak",
          gap: 2,
          p: 2,
          my: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "23.2px",
              letterSpacing: "0.2em",
              textAlign: "left",
            }}
          >
            {props.title ?? "INSIGHTS"}
          </Typography>

          <IconButton onClick={() => setInsightsOpen(false)}>
            <Close />
          </IconButton>
        </Box>
        {props.children}
      </Box>
    )
  );
};

export default Insight;
