import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

interface StartButtonProps {
  onClick: () => void;
  show: boolean;
}

const StartButton: React.FC<StartButtonProps> = ({ onClick, show }) => (
  <div
    onClick={onClick}
    style={{
      display: "inline-block",
      padding: "12px 24px",
      borderRadius: "12px",
      fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: 1.75,
      color: "rgba(0, 0, 0, 0.87)",
      backgroundColor: "#fff",
      boxShadow: "0px 10px 20px 0px rgba(159, 184, 215, 0.24)",
      cursor: "pointer",
      position: "relative",
      zIndex: 2,
      border: "2px solid transparent",
      outline: "none",
      userSelect: "none",
      transition: "all 0.3s ease",
      opacity: show ? 1 : 0,
      transform: `translateY(${show ? '0' : '20px'})`,
      pointerEvents: show ? 'auto' : 'none'
    }}
    onMouseEnter={(e) => {
      if (show) {
        e.currentTarget.style.borderColor = "rgba(159, 184, 215, 0.5)";
        e.currentTarget.style.transform = "translateY(-2px)";
      }
    }}
    onMouseLeave={(e) => {
      if (show) {
        e.currentTarget.style.borderColor = "transparent";
        e.currentTarget.style.transform = "translateY(0)";
      }
    }}
  >
    Start Chat
    <div
      style={{
        content: '""',
        position: "absolute",
        backgroundColor: "rgba(159, 184, 215, 0.3)",
        opacity: 0.3,
        width: "100%",
        height: "40%",
        bottom: -5,
        left: 0,
        borderRadius: "8px",
        boxShadow: "0px 0px 20px 20px rgba(159, 184, 215, 0.24)",
        zIndex: -1,
      }}
    />
  </div>
);

interface RecommendedChatOptionsProps {
  onSelectOption: (option: string) => void;
}

const RecommendedChatOptions: React.FC<RecommendedChatOptionsProps> = ({
  onSelectOption,
}) => {
  const [showContent, setShowContent] = useState(false);
  const initialMessage = "Hi Ellie! What are we learning today?";

  useEffect(() => {

    // delay showing the content to prevent flash
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        zIndex: 2,
        opacity: showContent ? 1 : 0,
        transition: "opacity 0.3s ease-in-out",
      }}
    >
      <Typography
        variant="body1"
        sx={{
          mb: 2,
          fontWeight: 500,
          fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
          fontSize: "1rem",
          lineHeight: 1.5,
          color: "primary.main",
          textAlign: "center",
          opacity: showContent ? 1 : 0,
          transform: `translateY(${showContent ? '0' : '20px'})`,
          transition: "all 0.3s ease-in-out"
        }}
      >
        Ready to begin your lesson?
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <StartButton
          onClick={() => onSelectOption(initialMessage)}
          show={showContent}
        />
      </Box>
    </Box>
  );
};

export default RecommendedChatOptions;