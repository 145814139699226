import React, { act, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { CloudUpload, ExpandMore } from "@mui/icons-material";
import styled from "@emotion/styled";
import formatBytes from "utils/bytesToReadable";
import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import Modal from "components/Modal";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const MAX_MATERIALS_SIZE_BYTES = 32e6;

const UploadMaterialModal = (props: any) => {
  const {
    courseId,
    currentSchoolId,
    currentClassroomTeacherId,
    selectedMaterial,
    open,
    onClose,
    activityId,
    concatMaterials,
  } = props;

  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState<MaterialTypeEnum>("video");
  const [filename, setFilename] = useState("");
  const [extension, setExtension] = useState("");
  const [sizeBytes, setSizeBytes] = useState<number>(0);
  const [courseMaterials, setCourseMaterials] = useState<
    Array<BaseCourseMaterialID>
  >([]);

  const onSubmit = async () => {
    try {
      const materials = [
        {
          link,
          name,
          type,
          activityId: activityId ? activityId : null,
          coursesId: courseId,
          sizeBytes,
        },
      ];
      const data = {
        schoolId: currentSchoolId,
        classroomTeacherId: currentClassroomTeacherId,
        courseId,
        materials,
        removeMaterials: Array<number>(),
      };

      if (selectedMaterial) {
        data.removeMaterials = [selectedMaterial.id];
        await api.course.updateActivitiesAndMaterials(data, 0);
      } else {
        if (courseId) {
          const createMaterialResponse = await api.material.create({
            material: materials[0],
          });
          if (createMaterialResponse) {
            concatMaterials([createMaterialResponse.data.material]);
            setCourseMaterials([
              ...courseMaterials,
              createMaterialResponse.data.material,
            ]);
          }
        }
      }

      setLink("");
      setName("");
      setType("video");
      setFilename("");
      setExtension("");

      onClose();
    } catch (e: any) {
      props.setSnackbar({ open: true, message: e.message, severity: "error" });
    }
  };

  const getType = (ext: string) => {
    switch (ext) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return "image";
      case "pdf":
      case "docx":
        return "document";
      default:
        throw new Error(`${ext} is an unsupported file type`);
    }
  };

  const handleOnChangeFile = async (e: any) => {
    setLink("");
    const newFile = e?.target?.files[0];
    if (newFile) {
      const formData = new FormData();
      formData.append("files", newFile);

      setLoading(true);
      try {
        let totalBytes = 0;
        if (activityId) {
          for (const material of courseMaterials) {
            if (material.activityId == activityId || !material.activityId) {
              totalBytes += material.sizeBytes ?? 0;
            }
          }
        } else {
          const activitiesSizeBytes: { [key: number]: number } = {};
          let courseSizeBytes = 0;
          for (const material of courseMaterials) {
            if (material.activityId) {
              activitiesSizeBytes[material.activityId] ??= 0;
              activitiesSizeBytes[material.activityId] +=
                material.sizeBytes ?? 0;
            } else {
              courseSizeBytes += material.sizeBytes ?? 0;
            }
          }
          totalBytes =
            Math.max(...Object.values(activitiesSizeBytes)) + courseSizeBytes;
        }
        if (totalBytes + newFile.size > MAX_MATERIALS_SIZE_BYTES) {
          props.setSnackbar({
            open: true,
            severity: "error",
            message: `This file is too large to be uploaded. The total size of this ${activityId !== undefined ? "activity's materials (including its course materials)" : "course's materials (including the course's activity with the largest amount of materials)"} may not exceed 32mb. This activity currently has ${formatBytes(totalBytes)}, and this file is ${formatBytes(newFile.size)}`,
          });
          return;
        }

        const response = await api.media.upload({
          schoolId: currentSchoolId,
          formData,
        });

        setLink(response.data.links[0].link);
        setFilename(newFile.name.split(".").shift());
        setType(getType(newFile.name.split(".").pop()));
        setExtension(newFile.name.split(".").pop());
        setSizeBytes(newFile.size);
        setName(newFile.name);
      } catch (e: any) {
        props.setSnackbar({
          open: true,
          severity: "error",
          message: e.message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClose = () => {
    onClose();

    setTimeout(() => {
      setLink("");
      setName("");
      setType("video");
      setFilename("");
      setExtension("");
      props.setSelectedMaterial();
    }, 500);
  };

  useEffect(() => {
    setLink(selectedMaterial?.link || "");
    setName(selectedMaterial?.name || "");
    setType(selectedMaterial?.type || "video");
    setFilename(selectedMaterial?.filename || "");
    setExtension(selectedMaterial?.extension || "");
  }, [selectedMaterial]);

  useEffect(() => {
    const getCourse = async () => {
      const course = await api.course.get({
        courseId,
        // TODO add a limit to the # of materials that can be associated to a course
        // So we don't have to workaround the pagination in this way
        per_page_materials: 9999,
      });

      setCourseMaterials(course.data.materials);
    };
    if (courseId) getCourse();
  }, []);

  return (
    <Modal
      disabled={loading || !name || !link}
      open={open}
      onClose={handleClose}
      onSubmit={onSubmit}
      title="Upload Material"
      submitText="Save"
      cancelText="Cancel"
    >
      <Typography fontFamily="Inter" fontSize={12} sx={{ maxWidth: 400 }}>
        Note: The documents will be truncated past 100 pages. Uploading large
        amounts of documents will result in increased latency on each chat
        message.
      </Typography>

      <Accordion sx={{ fontFamily: "Inter", boxShadow: "none", maxWidth: 400 }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ boxShadow: "none", border: "none", margin: 0 }}
        >
          <Typography fontFamily="Inter" margin={0}>
            Materials Information
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ width: "100%", gap: 1 }}>
          <Typography fontFamily={"Inter"} fontSize={14}>
            Materials are used in order to help Ellie better understand
            information that is not immediately available to her. For example,
            any proprietary information or current events that are crucial to
            the student's understanding of the learning objectives should be
            uploaded as a material.
          </Typography>
          {!activityId && (
            <Typography fontFamily={"Inter"} fontSize={14} marginTop={2}>
              Course materials will be used with every activity within the
              course
            </Typography>
          )}
          {activityId && (
            <Typography fontFamily={"Inter"} fontSize={14} marginTop={2}>
              Activity materials will be used within their own activity.
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            height: 250,
            width: 400,
            p: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={200} />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: 400,
            padding: 2,
          }}
        >
          {!selectedMaterial && (
            <>
              <Button
                sx={{ height: 50 }}
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUpload />}
              >
                <Typography fontFamily="Inter" fontSize={14} fontWeight="bold">
                  Upload PDF
                </Typography>
                <VisuallyHiddenInput
                  onChange={handleOnChangeFile}
                  type="file"
                  accept=".pdf"
                />
              </Button>
              {filename !== "" && extension !== "" && (
                <TextField
                  sx={{ flex: 1 }}
                  fullWidth
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              )}
              <Box sx={{ display: "flex", gap: 2 }}>
                <TextField
                  disabled
                  sx={{ flex: 3 }}
                  fullWidth
                  placeholder="File Name"
                  value={filename}
                  onChange={(e) => setFilename(e.target.value)}
                />
                <TextField
                  disabled
                  sx={{ flex: 1 }}
                  fullWidth
                  placeholder="Ext."
                  value={extension}
                  onChange={(e) => setExtension(e.target.value)}
                />
              </Box>
            </>
          )}
        </Box>
      )}
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  currentSchoolId: state.user.currentSchoolId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadMaterialModal);
