import React from "react";
import { Box, Button, Typography } from "@mui/material";

// @ts-expect-error TS(2307): Cannot find module 'assets/bookmark-icon.svg' or i... Remove this comment to see the full error message
import { ReactComponent as BookMarkIcon } from "assets/bookmark-icon.svg";

const MaterialItem = ({ material, setMaterialPreview }: any) => (
  <Box
    sx={{
      width: "80%",
      p: 2,
      backgroundColor: "#F4E8BD",
      borderRadius: 4,
      position: "initial",
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: 1,
      }}
    >
      <Box sx={{ flexShrink: 0 }}>
        <BookMarkIcon />
      </Box>
      <Typography
        fontWeight="bold"
        fontFamily="inter"
        sx={{
          wordBreak: "break-word",
          lineHeight: 1.4,
        }}
      >
        {material.name}
      </Typography>
    </Box>
    <Button
      sx={{
        mt: 2,
        fontSize: 12,
        textDecoration: "underline",
        textAlign: "left",
        color: "unset",
        padding: 0,
      }}
      variant="text"
      onClick={() => setMaterialPreview(material.link)}
    >
      View material
    </Button>
  </Box>
);

export default MaterialItem;
