import { cookieAuthInstance } from "lib/api/utils";

const activityGetChatHistory = ({
  activityId,
  perPage = 10,
  page = 1,
}: {
  activityId: string;
  perPage: number;
  page: number;
}) => {
  return cookieAuthInstance.get<CourseActivityPupilChatHistoryResponse>(
    `/activities/${activityId}/chat/?per_page=${perPage}&page=${page}`,
  );
};

const activityGetStudentChatHistory = ({
  activityId,
  studentId,
  perPage = 10,
  page = 1,
}: {
  activityId: string;
  studentId: string;
  perPage: number;
  page: number;
}) => {
  return cookieAuthInstance.get<CourseActivityPupilChatHistoryResponse>(
    `/activities/${activityId}/chat/${studentId}?per_page=${perPage}&page=${page}`,
  );
};

const activitySendMessage = async ({
  activityId,
  message,
}: {
  activityId: string;
  message: string;
}) => {
  return fetch(
    `${process.env.REACT_APP_API_URL}activities/${activityId}/chat/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ message }),
    },
  );
};

const activityUpdate = ({
  activityId,
  name,
  description,
  promptInstructions,
  llmRolesId,
  enabled,
  pinned,
}: {
  activityId: string;
  name: string;
  promptInstructions: string;
  llmRolesId: string;
  description: string;
  enabled: boolean;
  pinned: boolean;
}) => {
  return cookieAuthInstance.patch<CourseActivityResponse>(
    `/activities/${activityId}`,
    {
      name,
      description,
      promptInstructions,
      llmRolesId,
      enabled,
      pinned,
    },
  );
};

const activityUpdateObjectives = ({
  activityId,
  objectives,
}: {
  activityId: string;
  objectives: { id: string; details?: string }[];
}) => {
  return cookieAuthInstance.patch<CourseActivityResponse>(
    `/activities/${activityId}/objectives`,
    { objectives },
  );
};
const deleteCourseActivity = ({ activityId }: { activityId: string }) => {
  return cookieAuthInstance.delete<DBCourseActivity>(
    `/activities/${activityId}`,
  );
};
const activityAddObjectiveOrQuestion = ({
  activityId,
  objectives,
  questions,
}: CourseActivityAddObjectivesAndQuestionsRequest & { activityId: string }) => {
  return cookieAuthInstance.put<CourseActivityResponse>(
    `/activities/${activityId}`,
    {
      objectives,
      questions,
    },
  );
};

const activityToggle = ({
  activityId,
  enabled,
}: {
  activityId: string;
  enabled: boolean;
}) => {
  return cookieAuthInstance.patch<string>(
    `/activities/${activityId}/toggle/${enabled}`,
    {},
  );
};

const activityGetObjectives = async ({
  activityId,
}: {
  activityId: string;
}) => {
  return cookieAuthInstance.get<Array<DBCourseActivityObjective>>(
    `/activities/${activityId}/objectives`,
  );
};

const activityGetMaterials = async ({ activityId }: { activityId: string }) => {
  return cookieAuthInstance.get<Array<DBCourseMaterial>>(
    `/activities/${activityId}/materials`,
  );
};

const activity = {
  getChatHistory: activityGetChatHistory,
  sendMessage: activitySendMessage,
  update: activityUpdate,
  updateObjectives: activityUpdateObjectives,
  addObjectiveOrQuestion: activityAddObjectiveOrQuestion,
  delete: deleteCourseActivity,
  getStudentChatHistory: activityGetStudentChatHistory,
  toggle: activityToggle,
  getObjectives: activityGetObjectives,
  getMaterials: activityGetMaterials,
};

export default activity;
