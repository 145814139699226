import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { ChevronRight, ErrorOutline } from "@mui/icons-material";

import {
  setFlaggedCourses,
  setSelectedCourse,
  setCurrentClassroomId,
  setCurrentClassroomTeacherId,
} from "reducers/user";
// @ts-expect-error TS(2307): Cannot find module '../assets/tripple-dot-icon.svg... Remove this comment to see the full error message
import { ReactComponent as TrippleDotIcon } from "../assets/tripple-dot-icon.svg";

const CourseItem = (props: any) => {
  const history = useHistory();

  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: "white",
        padding: "15px 16px",
        boxShadow: "0px 4px 20px 0px #9FB8D766",
        borderRadius: 2,
        minWidth: 360,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgba(255,255,255,0.33)",
        },
      }}
      onClick={() => {
        props.setSelectedCourse(props.course);
        props.setCurrentClassroomTeacherId(props.course.classroomsTeachersId);
        props.setCurrentClassroomId(props.course.classroomId);
        if (props.flagged)
          props.setFlaggedCourses(
            props.flaggedCourses.filter(
              (courseId: any) => courseId !== props.course.id,
            ),
          );
        if (props.role === "teacher") history.push(`/class/${props.course.id}`);
        else history.push("/chat");
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <TrippleDotIcon />
          <Typography fontFamily="Inter">{props.course.name}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {!props.noEdit && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                props.setSelectedCourse(props.course);
                props.setCurrentClassroomTeacherId(
                  props.course.classroomsTeachersId,
                );
                props.setCurrentClassroomId(props.course.classroomId);
                history.push(`/class/${props.course.id}/edit`);
              }}
              variant="outlined"
              sx={{
                borderRadius: 1,
                px: 1,
                minWidth: 50,
                ":hover": {
                  backgroundColor: "white",
                },
              }}
            >
              <Typography fontFamily="Inter" fontSize={12} fontWeight="bold">
                Edit
              </Typography>
            </Button>
          )}
          {props.noEdit && <ChevronRight sx={{ opacity: 0.5 }} />}
          {props.flagged && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ErrorOutline color="error" />
              <Typography
                whiteSpace="nowrap"
                fontFamily="Inter"
                fontSize={10}
                fontWeight="bold"
                color="error.main"
              >
                No chat available
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  role: state.user.role,
  flaggedCourses: state.user.flaggedCourses,
});

const mapDispatchToProps = {
  setCurrentClassroomId,
  setCurrentClassroomTeacherId,
  setSelectedCourse,
  setFlaggedCourses,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseItem);
