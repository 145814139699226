import { Box, Button, Grid2, Typography } from "@mui/material";
import api from "lib/api";
import React, { useEffect, useState } from "react";
import MaterialItem from "./MaterialItem";
import { Add } from "@mui/icons-material";
import UploadMaterialModal from "components/modals/UploadMaterialModal";

const ActivityDetails = ({ activity }: { activity: any }) => {
  const [materials, setMaterials] = useState<BaseCourseMaterialID[]>();
  const [materialDialogOpen, setMaterialDialogOpen] = useState(false);

  useEffect(() => {
    const fetchMaterials = async () => {
      const courseId = activity?.activity?.coursesId;
      if (!courseId) setMaterials([]);

      const course = await api.course.get(
        { courseId },
      );

      setMaterials(course.data.materials);
    };

    fetchMaterials();
  }, [activity?.activity?.coursesId]);

  const [selectedMaterial, setSelectedMaterial] = useState<
    BaseCourseMaterialID | undefined
  >();

  const toggleMaterialDialogOpen = () =>
    setMaterialDialogOpen(!materialDialogOpen);

  return (
    <Grid2
      container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        gap: 8,
        fontFamily: "Inter",
        paddingTop: 2,
      }}
    >
      <Grid2 sx={{ display: "flex", flexDirection: "column", gap: 2, flex: 1 }}>
        <Typography fontFamily="Inter" fontWeight="bold">
          Instructions
        </Typography>
        <Typography fontFamily="Inter">
          {activity.activity.promptInstructions}
        </Typography>
      </Grid2>
      <Grid2
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          textAlign: "left",
          flex: 1,
        }}
      >
        <Typography fontFamily="Inter" fontWeight="bold">
          Objectives
        </Typography>
        {activity.objectives.map((objective: any, i: number) => {
          return (
            <Box sx={{ display: "flex", gap: 2 }} key={"objective-number-" + i}>
              <Typography fontFamily="Inter" fontWeight="bold">
                {i + 1}
              </Typography>
              <Typography fontFamily="Inter">{objective.details}</Typography>
            </Box>
          );
        })}
      </Grid2>
      <Grid2 sx={{ display: "flex", flexDirection: "column", gap: 2, flex: 1 }}>
        <Typography fontFamily="Inter" fontWeight="bold">
          Activity Materials
        </Typography>
        {materials
          ?.filter((el) => el.activityId === activity.activity.id)
          .map((material) => {
            return (
              <MaterialItem
                key={"material-item-activity-details-" + material.id}
                material={material}
                onClick={() => {
                  // todo implement this
                  console.log("clicked material");
                }}
                onRemove={async () => {
                  await api.material.delete(
                    { materialId: material.id.toString() },
                  );
                  setMaterials((materials) => {
                    return materials?.filter(
                      (curMaterial) => material.id !== curMaterial.id,
                    );
                  });
                }}
              />
            );
          })}
        {!materials?.filter((el) => el.activityId === activity.activity.id)
          .length && <Typography fontFamily={"Inter"}>None</Typography>}
      </Grid2>

      <UploadMaterialModal
        setMaterials={setMaterials}
        concatMaterials={(newMaterials: any[]) =>
          setMaterials((materials) => [...(materials ?? []), ...newMaterials])
        }
        selectedMaterial={selectedMaterial}
        setSelectedMaterial={setSelectedMaterial}
        courseId={activity.activity?.coursesId}
        activityId={activity.activity.id}
        open={materialDialogOpen}
        onClose={toggleMaterialDialogOpen}
      />
    </Grid2>
  );
};

export default ActivityDetails;
