import { cookieAuthInstance } from "lib/api/utils";
const noteGet = ({
  courseId,
  studentId,
}: {
  courseId: string;
  studentId: string;
}) => {
  return cookieAuthInstance.get<CoursePupilNoteResponse>(
    `/courses/${courseId}/notes/${studentId}`,
  );
};

const noteUpdate = ({
  courseId,
  studentId,
  note,
}: {
  courseId: string;
  studentId: string;
  note: string;
}) => {
  return cookieAuthInstance.put<CoursePupilNoteResponse>(
    `/courses/${courseId}/notes/${studentId}`,
    { note },
  );
};

const getBatchNotes = ({
  courseId,
  pupilIds,
}: {
  courseId: string;
  pupilIds: number[];
}) => {
  return cookieAuthInstance.post(
    `/courses/${courseId}/notes/batchNotes`,
    pupilIds,
  );
};

const note = {
  get: noteGet,
  update: noteUpdate,
  getBatch: getBatchNotes,
};

export default note;
