import { createTheme, ThemeOptions } from "@mui/material/styles";

// Extend the PaletteColor and PaletteOptions interfaces
declare module "@mui/material/styles" {
  interface Palette {
    card?: {
      main: string;
      mainHover: string;
    };
    graph?: {
      absent: string;
      completed: string;
      incomplete: string;
      excited: string;
      engaged: string;
      curious: string;
      confused: string;
      frustrated: string;
      bored: string;
      transparent: string;
    };
    inputs?: {
      main: string;
      light: string;
      weak: string;
      dark: string;
    };
    messages?: {
      sent: string;
      received: string;
    };
  }

  interface PaletteOptions {
    card?: {
      main: string;
      mainHover: string;
    };
    graph?: {
      absent: string;
      completed: string;
      incomplete: string;
      excited: string;
      engaged: string;
      curious: string;
      confused: string;
      frustrated: string;
      bored: string;
      transparent: string;
    };
    inputs?: {
      main: string;
      light: string;
      weak: string;
      dark: string;
    };
    messages?: {
      sent: string;
      received: string;
    };
  }

  interface PaletteColor {
    weak?: string;
    weakHover?: string;
  }

  interface SimplePaletteColorOptions {
    weak?: string;
    weakHover?: string;
  }

  interface TypeBackground {
    sideNav: string;
    weak: string;
  }
}

const theme = createTheme({
  palette: {
    card: {
      main: "#F7ECC5",
      mainHover: "#F3E2A5",
    },
    primary: {
      main: "#0959F3",
      light: "#0959F3",
      dark: "#0345C4",
      contrastText: "#FFF",
      weak: "#DBEBFF",
      weakHover: "#C2DDFF",
    },
    secondary: {
      main: "#001912",
      light: "#9FB8D7",
      dark: "#000000",
      weak: "#EFF4FB",
    },
    graph: {
      absent: "#FF000080",
      completed: "#0959F380",
      incomplete: "#798CA380",
      excited: "#F4E68B80",
      engaged: "#6ECC7880",
      curious: "#BEA7F380",
      confused: "#EBA25E80",
      frustrated: "#FF5E5580",
      bored: "#A5BEEC80",
      transparent: "#00000000",
    },
    background: {
      default: "#EFF3F8",
      sideNav: "#D8D8D8",
      weak: "#00000011",
    },
    inputs: {
      main: "#798CA3",
      light: "#FFFFFF",
      weak: "#D8D8D8",
      dark: "#E0E0E0",
    },
    messages: {
      sent: "#FFFFFF",
      received: "#91BBF04D",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          paddingLeft: "12px",
          paddingRight: "12px",
          boxShadow: "none",
          textTransform: "none",
        },
        contained: {
          paddingLeft: "24px",
          paddingRight: "24px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Besley", "Inter", "serif"].join(","),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

export default theme;
