import React from "react";
import { Box, Typography } from "@mui/material";

function getColor(
  label:
    | "Excited"
    | "Engaged"
    | "Curious"
    | "Confused"
    | "Frustrated"
    | "Bored",
) {
  switch (label) {
    case "Excited":
      return "graph.excited";
    case "Engaged":
      return "graph.engaged";
    case "Curious":
      return "graph.curious";
    case "Confused":
      return "graph.confused";
    case "Frustrated":
      return "graph.frustrated";
    case "Bored":
      return "graph.bored";
    default:
      throw new Error("Unsupported sentiment label");
  }
}

const SentimentLabels = ({
  label,
}: {
  label:
    | "Excited"
    | "Engaged"
    | "Curious"
    | "Confused"
    | "Frustrated"
    | "Bored"
    | "None";
}) => {
  if (label === "None") return <></>;
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        alignItems: "center",
        backgroundColor: getColor(label),
        height: 28,
        padding: 1,
        borderRadius: 20,
        width: "fit-content",
      }}
    >
      <Box
        sx={{
          width: 16,
          height: 16,
          backgroundColor: getColor(label),
          borderRadius: "50%",
          opacity: 1,
        }}
      />
      <Typography fontFamily="Inter" fontSize={14}>
        {label}
      </Typography>
    </Box>
  );
};

export default SentimentLabels;
