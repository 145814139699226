import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Grid2,
  Typography,
  Divider,
  TextField,
  InputAdornment,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { Add, Edit, Search } from "@mui/icons-material";

import api from "lib/api";
import { setSnackbar, setActivityError } from "reducers/ui";
import { clearUser, setSelectedActivity } from "reducers/user";
import UploadMaterialModal from "components/modals/UploadMaterialModal";
import MaterialList from "components/teacher/MaterialList";
import ActivityItem from "components/teacher/ActivityItem";
import SchoolCode from "components/teacher/SchoolCode";
import StudentTableCourse from "components/teacher/StudentTableCourse";

const CourseView = (props: any) => {
  const location = useLocation();
  const history = useHistory();
  const { courseId } = useParams<{ courseId: string }>();

  const [course, setCourse] = useState<CourseResponse | undefined>();
  const [materialDialogOpen, setMaterialDialogOpen] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState<
    BaseCourseMaterialID | undefined
  >();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [orderBy, setOrderBy] = useState<
    "name" | "newest" | "oldest" | "updated"
  >("name");
  const [showStudents, setShowStudents] = useState(false);

  const toggleMaterialDialogOpen = () =>
    setMaterialDialogOpen(!materialDialogOpen);

  const handleAddActivity = () => {
    history.push(`${location.pathname}/activity/add`);
  };

  useEffect(() => {
    props.setSelectedActivity();

    const fetchCourse = async () => {
      props.setActivityError(null);
      setLoading(true);
      try {
        const response = await api.course.get(
          {
            courseId,
          },
        );
        setCourse(response.data);
      } catch (e: any) {
        if (e.response?.status === 403) {
          props.setSnackbar({
            open: true,
            message:
              e.response.data.message ||
              "You don't have permission to access this course.",
            severity: "error",
          });
          history.push("/courses");
          return;
        } else {
          props.setSnackbar({
            open: true,
            message:
              e.response?.data?.message ||
              "An error occurred while loading the course",
            severity: "error",
          });
        }
      } finally {
        setLoading(false);
      }
    };

    if (courseId) fetchCourse();
    // eslint-disable-next-line
    return () => {
      props.setActivityError(null);
    };
  }, [courseId]);

  return (
    <Grid2
      container
      sx={{ display: "flex", flexDirection: "column", px: 24, py: 8, gap: 4 }}
    >
      <Grid2
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <Typography fontWeight={"bold"} variant="h4" fontFamily={"Inter"}>
            {course?.course.name}
          </Typography>
          <Button
            variant="text"
            sx={{
              textDecoration: "underline",
              padding: 0,
              fontFamily: "Inter",
            }}
            color="secondary"
            onClick={() => {
              setShowStudents(!showStudents);
            }}
          >
            {showStudents ? "Hide Students" : "See Students"}
          </Button>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="text"
            onClick={() => history.push(`${location.pathname}/report`)}
            sx={{
              position: "relative",
              textDecoration: "underline",
              padding: "0 8px",
              fontFamily: "Inter",
              height: 36,
            }}
            color="secondary"
          >
            <Typography fontFamily="Inter" fontWeight={"bold"}>
              Course Analytics
            </Typography>
            <Box
              sx={{
                position: "absolute",
                top: -6,
                right: -12,
                backgroundColor: "warning.main",
                borderRadius: "4px",
                padding: "0px 6px",
                transform: "scale(0.85)",
              }}
            >
              <Typography
                fontFamily="Inter"
                fontSize={10}
                fontWeight="bold"
                color="white"
                sx={{ lineHeight: "16px" }}
              >
                BETA
              </Typography>
            </Box>
          </Button>
          <Button
            variant="text"
            sx={{
              textDecoration: "underline",
              padding: "0 8px",
              fontFamily: "Inter",
              height: 36,
            }}
            endIcon={<Edit />}
            color="secondary"

            onClick={() => {
              history.push(location.pathname + "/edit");
            }}
          >
            <Typography fontFamily="Inter" fontWeight={"bold"}>
              Edit Course
            </Typography>
          </Button>
        </Box>
      </Grid2>
      {showStudents && <StudentTableCourse />}
      <Grid2 sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography fontWeight={"bold"} variant="h5" fontFamily={"Inter"}>
          Activities
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <TextField
            onChange={(value) => {
              setQuery(value.target.value);
            }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              },
            }}
            sx={{
              border: "none",
              "& .MuiInputBase-root": {
                height: 36,
                backgroundColor: "white",
                borderRadius: 1,
                border: "none",
              },
            }}
          />

          <FormControl
            variant="standard"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <span
              style={{
                color: "#757575",
                fontSize: "14px",
                fontFamily: "Inter",
              }}
            >
              Sort By:
            </span>
            <Select
              value={orderBy}
              onChange={(e) => {
                setOrderBy(
                  e.target.value as "name" | "newest" | "oldest" | "updated",
                );
              }}
              sx={{
                fontWeight: "semibold",
                fontSize: "14px",
                "& .MuiSelect-select": {
                  padding: "2px 8px",
                  fontFamily: "Inter",
                  fontWeight: "bold",
                },
                border: "none",
              }}
              disableUnderline
            >
              <MenuItem value="name" sx={{ fontFamily: "Inter" }}>
                Name
              </MenuItem>
              <MenuItem value="newest" sx={{ fontFamily: "Inter" }}>
                Newest
              </MenuItem>
              <MenuItem value="oldest" sx={{ fontFamily: "Inter" }}>
                Oldest
              </MenuItem>
              <MenuItem value="updated" sx={{ fontFamily: "Inter" }}>
                Updated
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          {course?.activities
            .filter((el) =>
              el.activity.name.toLowerCase().includes(query.toLowerCase()),
            )
            ?.sort((a, b) => {
              if (a.activity.pinned && !b.activity.pinned) {
                return -1;
              }
              if (b.activity.pinned && !a.activity.pinned) {
                return 1;
              }
              if (orderBy === "name") {
                return a.activity.name.localeCompare(b.activity.name);
              } else if (orderBy === "oldest") {
                return a.activity.createdAt.localeCompare(b.activity.createdAt);
              } else if (orderBy === "newest") {
                return b.activity.createdAt.localeCompare(a.activity.createdAt);
              } else if (orderBy === "updated") {
                b.activity.updatedAt.localeCompare(a.activity.updatedAt);
              }
              return 0;
            })
            .map((item: any) => (
              <ActivityItem
                item={item}
                key={`activity-item-${item.activity.id}`}
                setItem={(item: any) => {
                  const courseCopy = { ...course };
                  const index = courseCopy.activities.findIndex(
                    (el) => el.activity.id === item.activity.id,
                  );
                  if (index === -1)
                    throw new Error(
                      "failed to find index of activity while setting activity",
                    );
                  courseCopy.activities[index] = item;
                  setCourse(courseCopy);
                }}
              />
            ))}
        </Box>
        <Button
          variant="contained"
          startIcon={<Add />}
          sx={{
            borderRadius: 1,
            width: "fit-content",
          }}
          onClick={handleAddActivity}
        >
          <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
            Add Activity
          </Typography>
        </Button>
      </Grid2>
      <Divider />
      <Grid2 sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography fontWeight={"bold"} variant="h5" fontFamily={"Inter"}>
          Resources
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <SchoolCode />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "25%",
            }}
          >
            {
              <MaterialList
                materials={
                  course?.materials.filter((el) => !el.activityId) ?? []
                }
                onRemoveMaterial={async (material: BaseCourseMaterialID) => {
                  if (!course) return;
                  await api.material.delete(
                    { materialId: material.id.toString() }
                  );
                  const courseCopy = { ...course };
                  if (course?.materials)
                    courseCopy.materials = courseCopy.materials?.filter(
                      (el) => el.id !== material.id,
                    );
                  setCourse(courseCopy);
                }}
                toggleOpenDialog={() => { }}
                setSelectedMaterial={() => { }}
              />
            }
            <Button
              variant="outlined"
              onClick={toggleMaterialDialogOpen}
              startIcon={<Add />}
              sx={{
                width: "fit-content",
                borderRadius: 1,
              }}
            >
              <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
                Add Materials
              </Typography>
            </Button>
          </Box>
        </Box >
      </Grid2 >
      <UploadMaterialModal
        selectedMaterial={selectedMaterial}
        setSelectedMaterial={setSelectedMaterial}
        courseId={courseId}
        open={materialDialogOpen}
        onClose={toggleMaterialDialogOpen}
        concatMaterials={(materials: BaseCourseMaterialID[]) => {
          if (!course) throw new Error("expected course but got none.");
          setCourse((course) => ({
            ...course!,
            materials: course!.materials.concat(materials),
          }));
        }}
      />
    </Grid2 >
  );
};

const mapStateToProps = (state: any) => ({
  currentSchoolId: state.user.currentSchoolId,
  currentClassroomId: state.user.currentClassroomId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
});

const mapDispatchToProps = {
  setSelectedActivity,
  setSnackbar,
  clearUser,
  setActivityError
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseView);