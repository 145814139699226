import { cookieAuthInstance } from "lib/api/utils";

const deleteCourse = ({ courseId }: { courseId: string }) => {
  return cookieAuthInstance.delete<DBCourse>(`/courses/${courseId}`);
};

const courseCreate = ({
  classroomTeacherId,
  name,
  description,
  grade,
  activities,
  materials,
  startDate,
  endDate,
}: CourseCreateRequest) => {
  return cookieAuthInstance.post<CourseResponse>(`/courses/`, {
    name,
    description,
    grade,
    activities,
    materials,
    classroomTeacherId,
    startDate,
    endDate,
  });
};

const courseGet = ({
  courseId,
  per_page_materials = 1,
}: {
  courseId: string;
  per_page_materials?: number;
}) => {
  return cookieAuthInstance.get<CourseResponse>(
    `/courses/${courseId}${per_page_materials ? `?per_page_materials=${per_page_materials}` : ""}`,
  );
};

const courseUpdate = ({
  courseId,
  name,
  description,
  grade,
  startDate,
  endDate,
}: { courseId: string } & CourseBasicUpdateRequest) => {
  return cookieAuthInstance.patch<CourseResponse>(`/courses/${courseId}`, {
    name,
    description,
    grade,
    startDate,
    endDate,
  });
};

const courseUpdateActivitiesAndMaterials = (
  {
    courseId,
    activities,
    materials,
    removeActivities,
    removeMaterials,
    removeObjectives,
    removeQuestions,
  }: { courseId: string } & CourseUpdateActivitiesAndMaterialsRequest,
  per_page_materials: number,
) => {
  return cookieAuthInstance.put<CourseResponse>(
    `/courses/${courseId}${per_page_materials ? `?per_page_materials=${per_page_materials}` : ""}`,
    {
      activities,
      materials,
      removeActivities,
      removeMaterials,
      removeObjectives,
      removeQuestions,
    },
  );
};

const course = {
  create: courseCreate,
  get: courseGet,
  update: courseUpdate,
  updateActivitiesAndMaterials: courseUpdateActivitiesAndMaterials,
  delete: deleteCourse,
};

export default course;
