import axios from "axios";
import { handleUserLogout } from "utils/logout";
import { clearUser } from "reducers/user";
import { store } from "store";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data && error.response.data.message)
      error.message = error.response.data.message;

    return Promise.reject(error);
  },
);

export const createAuthHeaders = (token: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
};

export const authInstance = () =>
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

export const cookieAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

export const AxiosInterceptor = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    const interceptor = cookieAuthInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response?.status === 401) {
          try {
            await handleUserLogout(logout, () => store.dispatch(clearUser()));
          } catch (logoutError) {
            console.error("Error during logout:", logoutError);
            window.location.href = "/login";
          }
        }
        return Promise.reject(error);
      },
    );

    return () => {
      cookieAuthInstance.interceptors.response.eject(interceptor);
    };
  }, [logout]);

  return null;
};
