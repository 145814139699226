import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setSnackbar } from "reducers/ui";
import Modal from "components/Modal";
import api from "lib/api";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";

const EditClassroomModal = (props: {
  open: boolean;
  onClose: () => void;
  setImportedActivity: (activity: DBCourseActivity | null) => void;
  setIncludeMaterialsImportedActivity: (value: boolean) => void;
  setSnackbar: (params: {
    open: boolean;
    message: string;
    severity: string;
  }) => void;
  onSubmit: () => void;
}) => {
  const {
    open,
    onClose,
    setImportedActivity,
    setIncludeMaterialsImportedActivity,
    onSubmit,
  } = props;

  const [teacherActivities, setTeacherActivities] = useState<
    Array<DBCourseActivity>
  >([]);

  const [teacherCourses, setTeacherCourses] = useState<Array<DBCourse>>([]);
  const [selectedCourse, setSelectedCourse] = useState<DBCourse | null>(null);

  useEffect(() => {
    const fetchTeacherActivities = async () => {
      const activities = await api.teachers
        .listActivities()
        .catch(() => {
          props.setSnackbar({
            open: true,
            message: "Failed to fetch activities for the teacher",
            severity: "error",
          });
          return { data: [] };
        });
      setTeacherActivities(activities.data);
    };
    fetchTeacherActivities();

    const fetchTeacherCourses = async () => {
      const courses = await api.teachers
        .listCourses()
        .catch(() => {
          props.setSnackbar({
            open: true,
            message: "Failed to fetch courses for the teacher",
            severity: "error",
          });
          return { data: [] };
        });
      setTeacherCourses(courses.data);
    };
    fetchTeacherCourses();
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      title="Import Activity"
      submitText="Import"
      cancelText="Cancel"
    >
      <Box
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Autocomplete
          options={teacherCourses.map((el) => {
            return {
              id: `import-activity-course-${el.id}`,
              label: `${el.name}`,
            };
          })}
          sx={{ width: 300 }}
          renderInput={(params) => {
            return <TextField {...params} label="Select a Course" />;
          }}
          onChange={(_, value) => {
            setSelectedCourse(
              teacherCourses.find(
                (el) =>
                  `import-activity-course-${el.id?.toString()}` === value?.id,
              ) ?? null,
            );
          }}
        />
        {selectedCourse && (
          <Autocomplete
            options={teacherActivities
              .filter((activity) => activity.coursesId === selectedCourse.id)
              .map((el) => {
                return {
                  id: `import-activity-${el.id}`,
                  label: `${el.name}`,
                };
              })}
            sx={{ width: 300 }}
            renderInput={(params) => {
              return <TextField {...params} label="Activity" />;
            }}
            onChange={(_, value) => {
              setImportedActivity(
                teacherActivities.find(
                  (el) => `import-activity-${el.id?.toString()}` === value?.id,
                ) ?? null,
              );
            }}
          />
        )}
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              onChange={(_, checked) =>
                setIncludeMaterialsImportedActivity(checked)
              }
            />
          }
          label="Include Activity Materials"
        />
      </Box>
    </Modal>
  );
};

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(null, mapDispatchToProps)(EditClassroomModal);
