import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import Modal from "components/Modal";
import PlanSelector from "components/selectors/PlanSelector";

const CreateSchoolModal = (props: any) => {
  const { open, onClose } = props;
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhoneNumber, setContactPhoneNumber] = useState("");
  const [planId, setPlanId] = useState<string | undefined>();
  const [orgType, setOrgType] = useState(OrganizationType.EDUCATIONAL);

  const onSubmit = async () => {
    try {
      if (!planId)
        throw new Error(
          "Unable to create school. Expected a plan ID but did got none",
        );
      await api.school.create(
        {
          name,
          address,
          city,
          state,
          zipCode,
          contactEmail,
          contactPhoneNumber,
          planId: Number(planId),
          orgType,
        },
      );

      handleClose();
    } catch (error: any) {
      props.setSnackbar({
        open: true,
        severity: "error",
        message: error.message,
      });
    }
  };

  const handleClose = () => {
    setName("");
    setAddress("");
    setCity("");
    setState("");
    setZipCode("");
    setContactEmail("");
    setContactPhoneNumber("");
    setPlanId(undefined);
    setOrgType("");
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onSubmit={onSubmit}
      title="Create School"
      submitText="Create"
      cancelText="Cancel"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: 400,
          padding: 2,
        }}
      >
        <TextField
          fullWidth
          label="Name"
          type="text"
          autoComplete="organization"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          fullWidth
          label="Address"
          type="text"
          autoComplete="street-address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <TextField
          fullWidth
          label="City"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
        <TextField
          fullWidth
          label="State"
          value={state}
          onChange={(e) => setState(e.target.value)}
        />
        <TextField
          fullWidth
          label="ZIP Code"
          type="number"
          autoComplete="postal-code"
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
        />
        <TextField
          fullWidth
          label="Contact Email"
          type="email"
          autoComplete="email"
          value={contactEmail}
          onChange={(e) => setContactEmail(e.target.value)}
        />
        <TextField
          fullWidth
          label="Contact Phone Number"
          type="tel"
          autoComplete="tel"
          value={contactPhoneNumber}
          onChange={(e) => setContactPhoneNumber(e.target.value)}
        />
        <PlanSelector planId={planId} setPlanId={setPlanId} />
        <FormControl fullWidth>
          <InputLabel id="org-type-label">Organization Type</InputLabel>
          <Select
            labelId="org-type-label"
            id="org-type-select"
            value={orgType}
            label="Organization Type"
            onChange={(e) => setOrgType(e.target.value)}
          >
            <MenuItem value={OrganizationType.EDUCATIONAL}>
              Educational
            </MenuItem>
            <MenuItem value={OrganizationType.CORPORATE}>Corporate</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Modal>
  );
};

export const OrganizationType = {
  EDUCATIONAL: "educational",
  CORPORATE: "corporate",
};

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(null, mapDispatchToProps)(CreateSchoolModal);
