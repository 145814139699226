import { createAuthHeaders, instance, cookieAuthInstance } from "lib/api/utils";

const userAuthLogin = ({ email }: { email: string }, token: string) => {
  return instance.post<BaseUserLoginResponse>(
    `/users/auth/login`,
    {
      email,
    },
    createAuthHeaders(token),
  );
};

const userAuthLoginWithCookie = (
  { email }: { email: string },
  token: string,
) => {
  return cookieAuthInstance.post<BaseUserLoginResponse>(
    `/users/auth/login`,
    { email },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

const userAuthRegister = ({
  email,
  firstName,
  lastName,
  school,
  joinSchool,
  auth0_user_id,
}: UserRegisterRequest) => {
  return instance.post<BaseUserRegisterResponse>(`/users/auth/register`, {
    email,
    firstName,
    lastName,
    school,
    joinSchool,
    auth0_user_id,
  });
};

const userList = ({
  schoolId,
  roleId,
  page = 1,
  rowsPerPage = 10,
}: {
  schoolId: string;
  roleId: string;
  page?: number;
  rowsPerPage?: number;
}) => {
  let url = `/users/?page=${page}&per_page=${rowsPerPage}`;

  if (schoolId && roleId) {
    url += `&schools_id=${schoolId}&roles_id=${roleId}`;
  } else if (schoolId) {
    url += `&schools_id=${schoolId}`;
  } else if (roleId) {
    url += `&roles_id=${roleId}`;
  }

  return cookieAuthInstance.get<UsersWithSchoolRoleResponse>(url);
};

const userUpdate = ({
  email,
  firstName,
  lastName,
  profileImageLink,
}: {
  email: string;
  firstName: string;
  lastName: string;
  profileImageLink: string;
}) => {
  return cookieAuthInstance.patch<BaseUserID>(`/users/me`, {
    email,
    firstName,
    lastName,
    profileImageLink,
  });
};

const userMasterUpdate = ({
  userId,
  email,
  firstName,
  lastName,
  profileImageLink,
}: {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  profileImageLink: string;
}) => {
  return cookieAuthInstance.patch<BaseUserID>(`/users/${userId}`, {
    email,
    firstName,
    lastName,
    profileImageLink,
  });
};

const userAuthLogout = () => {
  return cookieAuthInstance.post("/users/auth/logout");
};

const user = {
  auth: {
    login: userAuthLogin,
    loginWithCookie: userAuthLoginWithCookie,
    register: userAuthRegister,
    logout: userAuthLogout,
  },
  list: userList,
  update: userUpdate,
  masterUpdate: userMasterUpdate,
};

export default user;
