import React from "react";
import { Box, Typography } from "@mui/material";
import { connect, useDispatch } from "react-redux";

import ObjectiveProgressIndicator from "./ObjectiveProgressIndicator";
import Avatar from "components/Avatar";
import StudentSentiment from "./StudentSentiment";
import { Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { showStudentConversation } from "actions/studentConversation";
import findMaxEmotion from "utils/findMaxEmotion";

const StudentItem = (props: any) => {
  const { selectedActivity, student, showNotes } = props;

  const dispatch = useDispatch();

  const handleVisibilityClick = () => {
    dispatch(showStudentConversation(student));
  };

  return (
    <Box
      sx={{
        gap: 2,
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, flex: 2 }}>
        <Avatar
          selectedUser={student}
          sx={{
            width: 32,
            height: 32,
            fontSize: 14,
          }}
        />
        <Typography fontFamily="Inter" fontSize={14}>
          {student?.name}
        </Typography>

        {selectedActivity && (
          <Box>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                {
                  handleVisibilityClick();
                }
              }}
              color="secondary"
              title="View student's conversation"
              sx={{ padding: "0.25rem" }}
            >
              <Visibility />
            </IconButton>
          </Box>
        )}
      </Box>
      {selectedActivity && (
        <>
          <Box sx={{ flex: 1 }}>
            <StudentSentiment sentiment={findMaxEmotion(student)} />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            {student?.analytics?.map((analysis: any, index: any) => (
              <ObjectiveProgressIndicator key={index} analysis={analysis} />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  selectedActivity: state.user.selectedActivity,
});

export default connect(mapStateToProps)(StudentItem);
