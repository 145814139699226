import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { Box, Button, IconButton, Switch, Typography } from "@mui/material";
import {
  Bookmark,
  ChevronLeft,
  Description,
  MoreVert,
  Pin,
  PushPin,
} from "@mui/icons-material";
import { setSelectedActivity } from "reducers/user";
import ObjectiveWithProgress from "components/teacher/ObjectiveWithProgress";
import { useAuth0 } from "@auth0/auth0-react";
import { setSnackbar } from "reducers/ui";
import api from "lib/api";

const ActivityItem = (props: any) => {
  const {
    selectedActivity,
    item: activity,
    open: forceOpen,
    objectivesProgress,
    objectivesProgressLoading,
    setItem,
  } = props;
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(forceOpen);
  }, [forceOpen]);

  const [activityEnabled, setActivityEnabled] = useState<boolean>(
    !!activity?.activity.enabled,
  );

  useEffect(() => {
    if (selectedActivity) setActivityEnabled(selectedActivity.activity.enabled);
  }, [selectedActivity]);

  return (
    <Box
      sx={{
        height: "",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 1,
        // p: 2,
        backgroundColor: open ? "primary.weak" : "primary.contrastText",
        borderRadius: 2,
        width: "300px",
        cursor: !selectedActivity ? "pointer" : "",
        transition: "opacity 0.3s ease-in-out",
        ":hover": {
          backgroundColor:
            !selectedActivity && !open ? "primary.weakHover" : "",
          boxShadow: !selectedActivity ? "0px 0px 10px rgba(0, 0, 0, 0.2)" : "",
        },
      }}
      onClick={() => {
        if (!selectedActivity) {
          props.setSelectedActivity(activity);
          history.push(`${location.pathname}/activity/${activity.activity.id}`);
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "center", p: 2 }}>
          <Box
            sx={{
              p: 1,
              borderRadius: 1,
              backgroundColor: "primary.weak",
              alignItems: "center",
              lineHeight: 0,
            }}
          >
            <Description color="disabled" sx={{}} />
          </Box>
          <Typography fontFamily="Inter" fontSize={14}>
            {activity.activity.name}
          </Typography>
        </Box>
        {!forceOpen && (
          <Box sx={{ display: "flex", alignSelf: "start", gap: 0.5, p: 1 }}>
            <IconButton
              sx={{ p: 0, height: 16 }}
              onClick={async (e) => {
                e.stopPropagation();
                await api.activity.update(
                  {
                    activityId: activity.activity.id,
                    name: activity.activity.name,
                    description: activity.activity.description,
                    promptInstructions: activity.activity.promptInstructions,
                    llmRolesId: activity.activity.llmRolesId,
                    enabled: activity.activity.enabled,
                    pinned: !activity.activity.pinned,
                  },
                );
                activity.activity.pinned = !activity.activity.pinned;
                setItem(activity);
              }}
            >
              <Bookmark
                sx={{ lineHeight: 0, height: 16, width: 16 }}
                color={activity.activity.pinned ? "primary" : "inherit"}
              />
            </IconButton>
            <IconButton
              sx={{ p: 0, height: 16 }}
              onClick={(e) => {
                setOpen((prev) => !prev);
                e.stopPropagation();
              }}
            >
              <MoreVert sx={{ lineHeight: 0, height: 16, width: 16 }} />
            </IconButton>
          </Box>
        )}
      </Box>
      {open && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            transition: "opacity 0.3s ease-in-out",
          }}
        >
          {activity?.objectives?.map((objective: any, index: any) =>
            selectedActivity ? (
              <ObjectiveWithProgress
                key={`objective-expand-activity-${index}`}
                description={objective.details}
                loading={objectivesProgressLoading}
                classProgress={objectivesProgress[objective.id]?.completed || 0}
                totalStudents={objectivesProgress[objective.id]?.total || 0}
              />
            ) : (
              <Typography fontFamily="Inter" fontSize={14} px={2} py={1}>
                <strong>Objective:</strong> {objective.details}
              </Typography>
            ),
          )}
          {selectedActivity && (
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                pl: 4,
              }}
            >
              <Box
                sx={{ display: "flex", gap: 2, alignItems: "center", my: 2 }}
              >
                <Box
                  sx={{
                    width: 20,
                    height: 10,
                    backgroundColor: "graph.completed",
                  }}
                />
                <Typography fontFamily="Inter" fontSize={14}>
                  Completed
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Box
                  sx={{
                    width: 20,
                    height: 10,
                    backgroundColor: "graph.incomplete",
                  }}
                />
                <Typography fontFamily="Inter" fontSize={14}>
                  Not Completed
                </Typography>
              </Box>
            </Box>
          )}
          {/* {selectedActivity && <SentimentPieChart />} */}
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  selectedActivity: state.user.selectedActivity,
});

const mapDispatchToProps = {
  setSelectedActivity,
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityItem);
