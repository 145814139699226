import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  Box,
  IconButton,
  Alert,
} from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import DeleteModal from "components/modals/DeleteModal";
import api from "lib/api";
import { setSnackbar, setActivityError } from "reducers/ui";
import { setSelectedActivity } from "reducers/user";
import InputWithLabel from "components/InputWithLabel";
import ParagraphSection from "components/ParagraphSection";
import LlmRoleSelector from "components/selectors/LlmRoleSelector";
import UploadMaterialModal from "components/modals/UploadMaterialModal";
import MaterialList from "components/teacher/MaterialList";
import Insight from "components/teacher/Insight";
import ImportActivityModal from "components/modals/ImportActivityModal";
import { CircularProgress } from "@mui/material";

const llmRolesId = "2";

/**
 * Page for adding or editing an activity.
 * @param {Object} props component props
 * @param {boolean} props.selectedActivity whether the user is editing an existing activity
 * @param {string} props.currentSchoolId current school id
 * @param {string} props.currentClassroomTeacherId current classroom teacher id
 * @param {string} props.courseId course id
 * @param {string} [props.activityId] activity id to edit
 * @param {Function} props.setSnackbar function to set the snackbar message
 * @param {Function} props.setSelectedActivity function to set the selected activity
 * @returns {ReactElement} The rendered AddActivity component
 */
const AddActivity = (props: any) => {
  const { selectedActivity, selectedCourse } = props;
  const history = useHistory();
  const { courseId, activityId } = useParams<{
    courseId?: string;
    activityId?: string;
  }>();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [promptInstructions, setPromptInstructions] = useState("");
  const [objectives, setObjectives] = useState<
    Array<{
      details: string;
      new: boolean;
      id?: string;
    }>
  >([
    {
      details: "",
      new: false,
    },
  ]);
  const [removeObjectives, setRemoveObjectives] = useState([] as number[]);
  const [selectedMaterial, setSelectedMaterial] = useState<
    BaseCourseMaterialID | undefined
  >();
  const [materialDialogOpen, setMaterialDialogOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [materials, setMaterials] = useState<Array<BaseCourseMaterialID>>([]);
  const enabled = selectedActivity?.activity.enabled ?? false;

  const [importedMaterials, setImportedMaterials] = useState<
    Array<DBCourseMaterial>
  >([]);
  const [importedActivity, setImportedActivity] =
    useState<DBCourseActivity | null>(null);
  const prevImportedActivityId = useRef<number | null>(null);
  const [importActivityModalOpen, setImportActivityModalOpen] = useState(false);
  const [
    includeMaterialsImportedActivity,
    setIncludeMaterialsImportedActivity,
  ] = useState(true);
  const [generatingMaps, setGeneratingMaps] = useState(false);

  const toggleMaterialDialogOpen = () =>
    setMaterialDialogOpen(!materialDialogOpen);

  const handleMapGeneration = async (activityId: string | number) => {
    setGeneratingMaps(true);
    try {
      await api.activities.generateMap({ activityId: activityId.toString() });

      history.push(`/class/${courseId}`);
    } catch (mapError: any) {
      console.log("Map generation error:", mapError?.response?.data);

      if (mapError?.response?.data?.error === "insufficient_context") {
        const errorMessage =
          mapError.response.data.reason || "Unable to generate knowledge map";
        props.setActivityError(errorMessage);

        try {
          // fetch fresh objectives data
          const objectivesResponse = await api.activity.getObjectives({
            activityId: activityId.toString(),
          });
          // set the activity with current values but with fresh objectives data
          props.setSelectedActivity({
            activity: {
              id: activityId,
              name,
              promptInstructions,
              llmRole: {
                id: llmRolesId,
              },
            },
            objectives: objectivesResponse.data.map((obj) => ({
              id: obj.id,
              details: obj.details,
              new: false,
            })),
          });

          history.push(`/class/${courseId}/activity/${activityId}/edit`);
          window.scrollTo({ top: 0, behavior: "smooth" });
        } catch (fetchError) {
          console.error("Error fetching objectives:", fetchError);
          // if we can't fetch fresh data, preserve existing objectives
          props.setSelectedActivity({
            activity: {
              id: activityId,
              name,
              promptInstructions,
              llmRole: {
                id: llmRolesId,
              },
            },
            objectives: objectives.map((obj) => ({
              ...obj,
              new: false,
            })),
          });
        }
      } else {
        console.error("Non-critical map generation error:", mapError);
        props.setSnackbar({
          open: true,
          message:
            "Activity saved successfully, but knowledge map generation will be retried later",
          severity: "info",
        });
        history.push(`/class/${courseId}`);
      }
    } finally {
      setGeneratingMaps(false);
    }
  };

  const handleAdd = async () => {
    props.setActivityError(null);
    setLoading(true);
    try {
      if (!courseId)
        throw new Error(
          "Unable to create activity. Expected a course ID but got none",
        );

      const response = await api.activities.createActivity({
        coursesId: Number(courseId),
        name,
        description: "",
        promptInstructions,
        llmRolesId: Number(llmRolesId),
        objectives,
        enabled,
        questions: [],
      });

      // update the materials that were created prior to creating the activity with the new activity id
      if (materials.length) {
        for (const material of materials) {
          if (response.data.id) {
            const materialUpdateRequest = {
              material: {
                id: material.id,
                coursesId: material.coursesId,
                name: material.name,
                link: material.link,
                type: material.type,
                sizeBytes: material.sizeBytes,
                activityId: response.data.id,
              },
            };
            console.log("Sending update request:", materialUpdateRequest);
            await api.material.update(materialUpdateRequest);
          }
        }
      }

      // create copies of the imported materials that are associated to the new activity
      if (importedMaterials.length) {
        for (const material of importedMaterials) {
          await api.material.create({
            material: {
              name: material.name,
              activityId: response.data.id,
              coursesId: Number(courseId),
              link: material.link,
              sizeBytes: material.sizeBytes ?? 0,
              type: material.type,
            },
          });
        }
      }

      if (response.data.id) {
        await handleMapGeneration(response.data.id);
      }
    } catch (e: any) {
      console.error("Activity creation error:", e);
      props.setSnackbar({
        open: true,
        message: e?.response?.data?.message || e.message,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    setLoading(true);
    try {
      if (!activityId)
        throw new Error(
          "Unable to update activity. Expected an activity ID but got none",
        );
      await api.activity.update({
        activityId,
        name,
        description: "",
        promptInstructions,
        llmRolesId,
        enabled,
        pinned: selectedActivity.activity.pinned,
      });

      // get current objectives from the database to compare
      const currentObjectivesResponse = await api.activity.getObjectives({
        activityId,
      });
      const currentObjectives = currentObjectivesResponse.data;

      // separate objectives into updates and new additions
      const objectivesToUpdate = objectives.filter((obj) => {
        const hasId = obj.id !== undefined && obj.id !== null;
        const exists = currentObjectives.some(
          (current) => String(current.id) === String(obj.id),
        );
        return hasId && exists;
      });

      const objectivesToCreate = objectives.filter(
        (obj) =>
          !obj.id ||
          !currentObjectives.some(
            (current) => String(current.id) === String(obj.id),
          ),
      );

      // handle updates first
      if (objectivesToUpdate.length > 0) {
        console.log("Sending update request with:", {
          activityId,
          objectives: objectivesToUpdate.map((obj) => ({
            id: obj.id!,
            details: obj.details,
          })),
        });

        await api.activity.updateObjectives({
          activityId,
          objectives: objectivesToUpdate.map((obj) => ({
            id: obj.id!,
            details: obj.details,
          })),
        });
      }

      // then handle new additions
      if (objectivesToCreate.length > 0) {
        console.log("Sending create request with:", {
          activityId,
          objectives: objectivesToCreate.map((obj) => ({
            details: obj.details,
          })),
        });

        await api.activity.addObjectiveOrQuestion({
          activityId,
          objectives: objectivesToCreate.map((obj) => ({
            details: obj.details,
          })),
        });
      }

      // finally handle removals
      if (removeObjectives.length > 0 && courseId) {
        console.log("Removing objectives:", removeObjectives);
        await api.course.updateActivitiesAndMaterials(
          {
            courseId,
            removeObjectives,
          },
          0,
        );
      }

      // generate map after all changes are saved
      await handleMapGeneration(Number(activityId));
    } catch (e: any) {
      console.error("Error in handleEdit:", e);
      props.setSnackbar({
        open: true,
        message: e?.response?.data?.message || e.message,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const addObjective = () => {
    setObjectives([
      ...objectives,
      {
        details: "",
        new: true,
        id: undefined,
      },
    ]);
  };

  const removeObjective = (index: any) => {
    setObjectives(objectives.filter((_, i) => i !== index));

    const removedObjective = objectives[index];
    if (removedObjective.id) {
      setRemoveObjectives((prev) => [...prev, Number(removedObjective.id)]);
    }
  };

  const updateObjective = (value: string, index: number) => {
    const updatedObjectives = objectives.map((objective, i) => {
      if (i === index) {
        return {
          ...objective,
          details: value,
          new: false,
        };
      }
      return objective;
    });
    setObjectives(updatedObjectives);
  };

  useEffect(() => {
    const setActivityMaterials = async (activityId: string) => {
      const materials = await api.activity.getMaterials({ activityId });
      setMaterials(materials.data as BaseCourseMaterialID[]);
    };
    if (selectedActivity) {
      setName(selectedActivity?.activity?.name || "");
      setPromptInstructions(
        selectedActivity?.activity?.promptInstructions || "",
      );
      // setLlmRolesId(selectedActivity?.activity?.llmRole.id || "");
      setObjectives(
        selectedActivity?.objectives || [
          {
            details: "",
          },
        ],
      );
      setActivityMaterials(selectedActivity!.activity.id.toString());
    }
  }, [selectedActivity]);

  useEffect(() => {
    if ((importedActivity?.id ?? null) === prevImportedActivityId.current)
      return;
    prevImportedActivityId.current = importedActivity?.id ?? null;
    setName(importedActivity?.name ?? "");
    setPromptInstructions(importedActivity?.promptInstructions ?? "");
    // setLlmRolesId(importedActivity?.llmRolesId.toString() ?? "");

    const fetchObjectives = async () => {
      if (!importedActivity?.id) {
        setObjectives([{ details: "", new: true }]);
        return;
      }
      const objectives = await api.activity.getObjectives({
        activityId: importedActivity.id.toString(),
      });
      setObjectives(
        objectives.data.map((el) => {
          return { details: el.details, new: true };
        }),
      );
    };

    const fetchMaterials = async () => {
      if (!importedActivity?.id) {
        setImportedMaterials([]);
        return;
      }

      const materials = await api.activity.getMaterials({
        activityId: importedActivity.id.toString(),
      });

      setImportedMaterials(materials.data);
    };
    fetchObjectives();
    if (includeMaterialsImportedActivity) fetchMaterials();
  }, [importedActivity]);

  return (
    <>
      <Grid
        container
        sx={{ pt: 2, px: 2, justifyContent: "center" }}
        spacing={4}
      >
        {props.activityError && (
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                backgroundColor: "#FFF3CD",
                color: "#664D03",
                p: 2,
                borderRadius: 1,
                border: 1,
                borderColor: "#FFE69C",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "100%",
                maxWidth: { xs: "100%", lg: "66.666%" },
                mb: 2,
              }}
            >
              {props.activityError
                .split("\n\n")
                .map((part: any, index: any) => (
                  <Typography
                    key={index}
                    fontFamily="Inter"
                    fontWeight="medium"
                  >
                    {part}
                  </Typography>
                ))}
              <Typography
                fontFamily="Inter"
                fontSize="14px"
                sx={{
                  borderTop: 1,
                  borderColor: "rgba(102, 77, 3, 0.2)",
                  pt: 2,
                  textAlign: "center",
                }}
              >
                Try editing your activity objectives to be more specific, or add
                relevant materials that provide context.
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} lg={4} mb={4}>
          <Box
            sx={{
              gap: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography fontFamily="Inter">
              Think of activities as a worksheet for the day. Each class can
              have multiple activities that align with objectives.
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputWithLabel
                  label="Activity Title"
                  placeholder={"Inflation"}
                  value={name}
                  handleValueChange={(e: any) => setName(e.target.value)}
                  isRequired
                />
              </Grid>
              <Grid item xs={12}>
                <InputWithLabel
                  multiline
                  label="Instructions"
                  placeholder={
                    "A 10th grade introduction to the basic tools of economics. Here you will learn about general market values, inflation, and much more."
                  }
                  value={promptInstructions}
                  handleValueChange={(e: any) =>
                    setPromptInstructions(e.target.value)
                  }
                  isRequired
                />
              </Grid>
              <Grid item xs={12}>
                {objectives.map((objective, index) => (
                  <Box key={index} sx={{ position: "relative" }}>
                    <InputWithLabel
                      multiline
                      label="Objectives"
                      placeholder={"Define the cause of inflation"}
                      value={objective.details}
                      handleValueChange={(e: any) =>
                        updateObjective(e.target.value, index)
                      }
                      fullWidth
                      noLabel={index > 0}
                      isRequired
                    />
                    {index > 0 && (
                      <IconButton
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          backgroundColor: "rgba(255,255,255,0.8)",
                        }}
                        onClick={() => removeObjective(index)}
                      >
                        <Close />
                      </IconButton>
                    )}
                  </Box>
                ))}
                {objectives.length >= 4 && (
                  <Alert severity="error" sx={{ fontFamily: "Inter", mb: 2 }}>
                    Our system is designed to best function with 2-3 objectives.
                  </Alert>
                )}
                <Button
                  onClick={addObjective}
                  color="primary"
                  variant="outlined"
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 600,
                    borderRadius: 1,
                    mb: 1,
                  }}
                  startIcon={<Add />}
                  disabled={objectives.length >= 4}
                  title={
                    objectives.length >= 4
                      ? "There is a limit of only 4 objectives per activity"
                      : ""
                  }
                >
                  Add Objective
                </Button>
              </Grid>

              {/* <Grid item xs={12}>
                <LlmRoleSelector
                  llmRolesId={llmRolesId}
                  setLlmRolesId={setLlmRolesId}
                />
              </Grid> */}

              {!activityId && (
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "19.36px",
                      letterSpacing: "0.02em",
                      textAlign: "left",
                      display: "flex",
                      pb: 2,
                    }}
                  >
                    Want to pull content from another activity?
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: "4px" }}
                    title="Import an activity that has already been created for another course"
                    onClick={() => setImportActivityModalOpen(true)}
                  >
                    <Typography
                      fontFamily="Inter"
                      fontWeight="bold"
                      fontSize={14}
                    >
                      Pull from Activity Library
                    </Typography>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} lg={4} mb={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box sx={{ width: "100%", mt: 0 }}>
              <MaterialList
                setSelectedMaterial={setSelectedMaterial}
                toggleOpenDialog={toggleMaterialDialogOpen}
                materials={materials}
                onRemoveMaterial={async (material) => {
                  setMaterials((materials) =>
                    materials.filter(
                      (newMaterial) => newMaterial.id !== material.id,
                    ),
                  );
                  api.material.delete({ materialId: material.id.toString() });
                }}
                addingActivity={true}
                activityId={activityId}
              />
              {!!importedMaterials.length && (
                <MaterialList
                  imported
                  materials={importedMaterials as BaseCourseMaterialID[]}
                  setSelectedMaterial={setSelectedMaterial}
                  toggleOpenDialog={toggleMaterialDialogOpen}
                  onRemoveMaterial={async (material) => {
                    setImportedMaterials((materials) =>
                      materials.filter(
                        (newMaterial) => newMaterial.id !== material.id,
                      ),
                    );
                  }}
                  activityId={activityId}
                />
              )}
            </Box>
            {!activityId && (
              <Insight>
                <ParagraphSection
                  title="Activity Title"
                  description=" Provide a concise title, this will help you and your students quickly identify the task."
                />
                <ParagraphSection
                  title="Instructions"
                  description=" Write a brief description of the activity. Include any relevant instructions or important details that students need to know before starting."
                />
                <ParagraphSection
                  title="Ellie’s Role"
                  description="This text provides insight as to how to guide and assist students through each activity."
                />
                <ParagraphSection
                  title="Objectives"
                  description="Define what students should learn or achieve by completing the activity. Clearly defined objectives will guide students and ensure that the activity meets educational goals."
                />
              </Insight>
            )}
          </Box>
        </Grid>
        <UploadMaterialModal
          selectedMaterial={selectedMaterial}
          setSelectedMaterial={setSelectedMaterial}
          courseId={courseId}
          open={materialDialogOpen}
          onClose={toggleMaterialDialogOpen}
          activityId={activityId}
          concatMaterials={(materials: Array<BaseCourseMaterialID>) =>
            setMaterials((prev) => [...prev, ...materials])
          }
          creatingActivity={!selectedActivity}
        />
        <ImportActivityModal
          open={importActivityModalOpen}
          onClose={() => {
            setImportActivityModalOpen(false);
            setImportedActivity(null);
          }}
          setImportedActivity={setImportedActivity}
          setIncludeMaterialsImportedActivity={
            setIncludeMaterialsImportedActivity
          }
          onSubmit={() => setImportActivityModalOpen(false)}
        />
        <DeleteModal
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          itemTitle={selectedActivity?.activity.name}
          onClose={() => {
            setDeleteModalOpen(false);
          }}
          deleteFunction={async () => {
            setDeleteModalOpen(false);
            await api.activity
              .delete({
                activityId: selectedActivity?.activity.id,
              })
              .then(() => {
                props.setSnackbar({
                  severity: "success",
                  message: "Successfully deleted activity",
                });
                history.push(`/class/${courseId}`);
              });
          }}
        />
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          gap: 2,
          mx: 38,
          pb: 8,
          pt: 2,
          borderTop: "2px solid",
          borderTopColor: "background.sideNav",
        }}
      >
        {selectedActivity && (
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              setDeleteModalOpen(true);
            }}
            sx={{ borderRadius: 1 }}
          >
            <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
              Delete Activity
            </Typography>
          </Button>
        )}
        <Button
          variant="outlined"
          onClick={async () => {
            await Promise.all(
              materials.map((el) =>
                api.material.delete({ materialId: el.id.toString() }),
              ),
            );
            history.push(`/class/${courseId}`);
          }}
          sx={{ borderRadius: 1 }}
        >
          <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
            Cancel
          </Typography>
        </Button>
        <Button
          disabled={
            loading ||
            name === "" ||
            promptInstructions === "" ||
            objectives[0].details === ""
          }
          onClick={selectedActivity ? handleEdit : handleAdd}
          color="primary"
          variant="contained"
          sx={{ borderRadius: 1 }}
        >
          <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
            Save Activity
          </Typography>
        </Button>
      </Box>
      {generatingMaps && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            zIndex: 1300,
            gap: 2,
          }}
        >
          <CircularProgress size={48} />
          <Typography
            fontFamily="Inter"
            fontSize={16}
            color="primary"
            sx={{ mt: 2 }}
          >
            Generating activity...
          </Typography>
          <Typography
            fontFamily="Inter"
            fontSize={14}
            color="text.secondary"
            sx={{ maxWidth: 400, textAlign: "center" }}
          >
            This may take a moment while we analyze the objectives and provided
            materials.
          </Typography>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  selectedActivity: state.user.selectedActivity,
  currentSchoolId: state.user.currentSchoolId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
  selectedCourse: state.user.selectedCourse,
  activityError: state.ui.activityError,
});

const mapDispatchToProps = {
  setSnackbar,
  setSelectedActivity,
  setActivityError,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddActivity);
