import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  Box,
  CircularProgress,
  Typography,
  Paper,
  Avatar,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useAuth0 } from "@auth0/auth0-react";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import AppLine from "components/teacher/AppLine";
import StudentItem from "components/teacher/StudentItem";
import Modal from "components/Modal";
import SchoolCode from "./SchoolCode";

const StudentTableCourse = (props: any) => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    noRefresh,
    refreshing,
    currentSchoolId,
    currentSchoolCode,
    currentClassroomId,
  } = props;

  const { courseId, activityId } = useParams<{
    courseId?: string;
    activityId?: string;
  }>();

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const [students, setStudents] = useState<Array<any>>([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState<any | null>(null);

  const handleDeleteClick = (student: any) => {
    setStudentToDelete(student);
    setDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = () => {
    if (studentToDelete) {
      removeStudent(studentToDelete.id);
    }
    setDeleteConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmOpen(false);
    setStudentToDelete(null);
  };

  const removeStudent = async (studentId: any) => {
    try {
      setLoading(true);
      await api.classroom.updatePupils(
        {
          classroomId: currentClassroomId,
          removePupils: [studentId],
        });

      const updatedStudents = students
        .map((page) => page.filter((student: any) => student.id !== studentId))
        .filter((page) => page.length > 0);

      setStudents(updatedStudents);
      props.setSnackbar({
        open: true,
        message: "Student removed successfully",
        severity: "success",
      });
    } catch (e: any) {
      props.setSnackbar({
        open: true,
        message: e.message,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchStudents = async () => {
      setLoading(true);

      if (props.setObjectivesProgressLoading) {
        setTimeout(() => {
          props.setObjectivesProgressLoading(true);
        }, 50);
      }

      try {
        const response = await api.school.listClassrooms(
          {
            schoolId: currentSchoolId,
            rowsPerPage: 9999,
            pupilsPerPage: 99999,
            teachersPerPage: 99999,
            mine: true,
          });

        // get list of students from the matching classroom
        const classroomStudents =
          response.data.classrooms.find(
            (c: any) => c.classroom.id === currentClassroomId,
          )?.pupils || [];

        let fetchedStudents = await Promise.all(
          classroomStudents.map(async (student: any) => {
            return {
              id: student.id,
              name: student.firstName + " " + student.lastName,
              firstName: student.firstName,
              lastName: student.lastName,
              email: student.email,
              profileImageLink: student.profileImageLink,
            };
          }) || [],
        );

        setStudents([fetchedStudents]);
      } catch (e: any) {
        props.setSnackbar({
          open: true,
          message: e.message,
          severity: "error",
        });
      } finally {
        setLoading(false);
      }
    };
    fetchStudents();
  }, [courseId]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "inputs.light",
        p: 4,
        borderRadius: 2,
        transition: "opacity 0.3s ease-in-out",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
        {refreshing && (
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              top: 30,
              right: 30,
              gap: 2,
            }}
          >
            <Typography fontFamily="Inter" fontSize={14} color="error">
              Refreshing
            </Typography>
            <CircularProgress size={20} color="error" />
          </Box>
        )}
        <Typography fontFamily="Inter" fontSize={24}>
          Students
        </Typography>
      </Box>
      <SchoolCode oneLine />
      <AppLine color="lightgrey" sx={{ mb: 2 }} />
      <Box sx={{ display: "flex", mb: 2, gap: 1, alignItems: "center" }}>
        {/* Name column - takes up 2 parts */}
        <Box sx={{ flex: 2, display: "flex", alignItems: "center", gap: 1 }}>
          <Typography fontFamily="Inter" fontSize={16}>
            Name
          </Typography>
        </Box>
        <Box sx={{ width: 48 }} /> {/* Spacer for delete button */}
      </Box>
      <AppLine color="lightgrey" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box
          sx={{
            overflowY: "scroll",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress size={120} />
            </Box>
          ) : (
            students &&
            students.length > 0 &&
            students[page - 1].map((student: any, index: any) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 0.5,
                  mt: index === 0 ? 0.5 : "",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ flex: 1, display: "flex" }}>
                    <StudentItem student={student} />
                  </Box>
                  <IconButton
                    onClick={() => handleDeleteClick(student)}
                    color="error"
                    sx={{ ml: 2, height: "32px" }}
                  >
                    <Delete />
                  </IconButton>
                </Box>
                <AppLine color="lightgray" />
              </Box>
            ))
          )}
        </Box>
      </Box>
      <Modal
        open={deleteConfirmOpen}
        onClose={handleCancelDelete}
        onSubmit={handleConfirmDelete}
        submitText="Remove"
        cancelText="Cancel"
        maxWidth="md"
        fullWidth
      >
        <Paper
          elevation={0}
          sx={{ p: 3, backgroundColor: "background.default" }}
        >
          <Typography variant="body1" paragraph>
            Are you sure you want to remove this student?
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              my: 2,
              p: 2,
              bgcolor: "background.paper",
            }}
          >
            <Avatar
              src={studentToDelete?.profileImageLink}
              sx={{ width: 48, height: 48, mr: 2 }}
            >
              {studentToDelete?.name.charAt(0)}
            </Avatar>
            <Typography variant="h6">{studentToDelete?.name}</Typography>
          </Box>
        </Paper>
      </Modal>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  currentSchoolId: state.user.currentSchoolId,
  currentSchoolCode: state.user.currentSchoolCode,
  currentClassroomId: state.user.currentClassroomId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
  selectedActivity: state.user.selectedActivity,
});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentTableCourse);
