import React, { useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Clear, Close, Delete, X } from "@mui/icons-material";
import DeleteModal from "components/modals/DeleteModal";
import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import { connect } from "react-redux";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

const MaterialItem = (props: {
  material: BaseCourseMaterialID;
  onClick: () => void;
  onRemove: (material: BaseCourseMaterialID) => void;
  setSnackbar?: ActionCreatorWithPayload<any, "ui/setSnackbar">;
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <Grid item sx={{ my: 1, height: "fit-content" }}>
      <Box
        onClick={props.onClick}
        sx={{
          display: "flex",
          flexDirection: "column",
          py: 1,
          px: 2,
          backgroundColor: "card.main",
          borderRadius: 2,
          cursor: "pointer",
          overflow: "hidden",
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
          ":hover": {
            backgroundColor: "card.mainHover",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: "fit-content",
          }}
        >
          <Typography fontWeight={100} fontSize={12}>
            PDF
          </Typography>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              props.onRemove(props.material);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
        <Box>
          <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
            {props.material.name}
          </Typography>
        </Box>
      </Box>
      <DeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        itemTitle={props.material.name}
        deleteFunction={async () => {
          setDeleteModalOpen(false);
          await api.material
            .delete(
              { materialId: props.material.id.toString() },
            )
            .then(() => {
              props.onRemove(props.material);
              props.setSnackbar?.({
                severity: "success",
                message: "Successfully deleted material",
              });
            });
        }}
      />
    </Grid>
  );
};

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(null, mapDispatchToProps)(MaterialItem);
