import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, Button, TableCell, Typography } from "@mui/material";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import EnhancedTable from "components/DataTable";
import CreateSchoolModal from "components/modals/CreateSchoolModal";
import EditSchoolModal from "components/modals/EditSchoolModal";

const SchoolPage = (props: any) => {
  const [schools, setSchools] = useState([] as any);
  const [createSchoolModalOpen, setCreateSchoolModalOpen] = useState(false);
  const [editSchoolModalOpen, setEditSchoolModalOpen] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const toggleCreateSchoolModalOpen = () =>
    setCreateSchoolModalOpen(!createSchoolModalOpen);

  const toggleEditSchoolModalOpen = () =>
    setEditSchoolModalOpen(!editSchoolModalOpen);

  const rowCells = (row: any) => (
    <>
      <TableCell align="left">{row.id}</TableCell>
      <TableCell align="right">{row.name}</TableCell>
      <TableCell align="right">
        {row.address}, {row.city},{row.state} {row.zipCode}
      </TableCell>
      <TableCell align="right">{row.contactEmail}</TableCell>
      <TableCell align="right">{row.contactPhoneNumber}</TableCell>
      <TableCell align="right">{row.schoolCode}</TableCell>
    </>
  );

  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "ID",
    },
    {
      id: "name",
      numeric: true,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "address",
      numeric: true,
      disablePadding: false,
      label: "Address",
    },
    {
      id: "contactEmail",
      numeric: true,
      disablePadding: false,
      label: "Contact Email",
    },
    {
      id: "contactPhoneNumber",
      numeric: true,
      disablePadding: false,
      label: "Contact Phone Number",
    },
    {
      id: "schoolCode",
      numeric: true,
      disablePadding: false,
      label: "School Code",
    },
  ];

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await api.school.list(
          { page: page + 1, rowsPerPage },
        );
        if (response.data?.schools?.length === 0 && page > 0)
          setPage((page) => page - 1);
        else if (
          response.data?.schools?.length > 0 &&
          !(schools.length > rowsPerPage * page)
        )
          if (page > 0)
            setSchools((schools: any) =>
              [
                ...schools,
                ...response.data.schools.map((item: any) => item.school),
              ].filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.id === value.id),
              ),
            );
          else
            setSchools(response.data.schools.map((item: any) => item.school));
        else if (response.data?.schools?.length > 0 && page === 0)
          setSchools(response.data.schools.map((item: any) => item.school));
      } catch (error: any) {
        props.setSnackbar({
          open: true,
          message: `Error fetching schools: ${error.message}`,
          severity: "error",
        });
      }
    };

    if (!createSchoolModalOpen && !editSchoolModalOpen) fetchSchools();
    // eslint-disable-next-line
  }, [createSchoolModalOpen, editSchoolModalOpen, page, rowsPerPage]);

  return (
    <Box>
      <Typography variant="h4" textAlign="center" mb={2}>
        Schools
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "end", marginBottom: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={toggleCreateSchoolModalOpen}
        >
          Create School
        </Button>
      </Box>

      <EnhancedTable
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        filterDisabled
        selectionDisabled
        rows={schools}
        rowCells={rowCells}
        headCells={headCells}
        onRowClick={(school: any) => {
          setSelectedSchool(school);
          toggleEditSchoolModalOpen();
        }}
      />

      <CreateSchoolModal
        open={createSchoolModalOpen}
        onClose={toggleCreateSchoolModalOpen}
      />

      <EditSchoolModal
        selectedSchool={selectedSchool}
        open={editSchoolModalOpen}
        onClose={toggleEditSchoolModalOpen}
      />
    </Box>
  );
};

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(null, mapDispatchToProps)(SchoolPage);
