export default class StudentWebsocket {
  private ws: WebSocket | null = null;

  private connectionPromise: Promise<void> | null = null;

  private static instance: StudentWebsocket;

  private url = `${process.env.REACT_APP_API_URL?.replace("http", "ws").replace(
    "https",
    "wss",
  )}ws/student`;

  public readonly eventTarget = new EventTarget();

  private constructor() {
    this.connect();
  }

  private async connect() {
    if (this.ws) return;

    this.ws = new WebSocket(this.url);

    this.connectionPromise = new Promise((res, rej) => {
      this.ws!.onopen = () => {
        console.log("websocket connection opened");
        res();
      };

      this.ws!.onerror = (e) => {
        this.ws = null;
        console.error(e);
        rej();
      };

      this.ws!.onclose = () => {
        this.ws = null;
        rej();
      };
    });

    this.ws.onmessage = (e) => {
      let data = e.data.toString();
      if (data === "ping") return this.ws?.send("pong");

      data = JSON.parse(data);

      const event = new CustomEvent(data.type, { detail: data.data });
      this.eventTarget.dispatchEvent(event);
    };
  }

  public static getInstance() {
    return (this.instance ??= new StudentWebsocket());
  }
}
