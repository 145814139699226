import { cookieAuthInstance, instance } from "lib/api/utils";
const adminAuthLogin = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  return instance.post<BaseAdminResponse>(`/admins/auth/login`, {
    email,
    password,
  });
};

const adminAuthCreate = ({
  email,
  password,
  firstName,
  lastName,
}: {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}) => {
  return cookieAuthInstance.post<AdminResponse>(`/admins/auth/new`, {
    email,
    password,
    firstName,
    lastName,
  });
};

const adminList = ({ page = 1, rowsPerPage = 10 }) => {
  return cookieAuthInstance.get<AdminsResponse>(
    `/admins/?page=${page}&per_page=${rowsPerPage}`,
  );
};

const adminUpdate = ({
  email,
  newPassword,
  oldPassword,
  firstName,
  lastName,
}: {
  email: string;
  newPassword: string;
  oldPassword: string;
  firstName: string;
  lastName: string;
}) => {
  return cookieAuthInstance.patch<BaseAdminResponse>(`/admins/`, {
    email,
    newPassword,
    oldPassword,
    firstName,
    lastName,
  });
};

const adminMasterUpdate = ({
  adminId,
  email,
  newPassword,
  oldPassword,
  firstName,
  lastName,
}: {
  adminId: string;
  email: string;
  newPassword: string;
  oldPassword: string;
  firstName: string;
  lastName: string;
}) => {
  return cookieAuthInstance.patch<BaseAdminResponse>(`/admins/${adminId}`, {
    email,
    newPassword,
    oldPassword,
    firstName,
    lastName,
  });
};

const admin = {
  auth: {
    login: adminAuthLogin,
    create: adminAuthCreate,
  },
  list: adminList,
  update: adminUpdate,
  masterUpdate: adminMasterUpdate,
};

export default admin;
