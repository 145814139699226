import React, { useEffect, useRef, useState } from "react";

type Circle = {
  r: number;
  x: number;
  y: number;
};

const canvasHeight = 250;
const canvasWidth = 250;

function calculateCirclePositions(percentages: number[]): Circle[] {
  // using the sqrt of the pct expresses the difference in pct's in proportion to the area of the circles
  const radii = percentages.map((p) => Math.sqrt(p) * 3 + 35);
  const circles: Circle[] = [];
  const overlap = 10; // amount of overlap between each circle in px

  // Place circles in a square pattern with a little bit of overlap on their borders
  circles.push({ r: radii[0], x: 0, y: 0 });
  circles.push({
    r: radii[1],
    x: radii[0] + radii[1] - overlap,
    y: 0,
  });
  const x3 = 0;
  const y3 = radii[0] + radii[2] - overlap;
  circles.push({
    r: radii[2],
    x: x3,
    y: y3,
  });
  const x4 = radii[2] + radii[3] - overlap;
  const y4 = radii[1] + radii[3] - overlap;
  circles.push({
    r: radii[3],
    x: x4,
    y: y4,
  });

  // Rotate the circles 45 degrees clockwise (turning a square of circles into a diamond of circles)
  const angle = -Math.PI / 4; // -45 degrees in radians
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);

  circles.forEach((circle) => {
    const { x, y } = circle;
    circle.x = x * cos - y * sin;
    circle.y = x * sin + y * cos;
  });

  // Center the circles in the canvas
  // calculate the bounding box, then translate it so the center of the bounding box is the center of the canvas
  const bounds = calculateBounds(circles);
  const offsetX = canvasWidth / 2 - (bounds.minX + bounds.maxX) / 2;
  const offsetY = canvasHeight / 2 - (bounds.minY + bounds.maxY) / 2;
  circles.forEach((circle) => {
    circle.x += offsetX;
    circle.y += offsetY;
  });

  return circles;
}

// this calculates the coordinates of a rectangle around the circles aka the 'bounding box'
function calculateBounds(circles: Circle[]) {
  const minX = Math.min(...circles.map((c) => c.x - c.r));
  const maxX = Math.max(...circles.map((c) => c.x + c.r));
  const minY = Math.min(...circles.map((c) => c.y - c.r));
  const maxY = Math.max(...circles.map((c) => c.y + c.r));
  return { minX, maxX, minY, maxY };
}

const ObjectiveBubbleChart = ({
  students,
  objective,
}: {
  students: Array<{
    id: any;
    name: string;
    firstName: any;
    lastName: any;
    email: any;
    profileImageLink: any;
    notes: string;
    analytics: LatestObjectivesSummaryResponse[] | null;
    sentiment_analytics: CourseActivityObjectiveSentimentAnalytics[];
  }>;
  objective: any;
}) => {
  const totalStudents = students.length;
  const understandingLevelValues = [0, 0, 0, 0];
  for (const student of students) {
    const analytics = student.analytics?.[0];
    if (!analytics) {
      continue;
    }

    const studentObjective = analytics.objectives_summary.find(
      (el) => el.objective_id === objective.id,
    );

    if (!studentObjective) {
      continue;
    }

    if (studentObjective.understanding_level === "No Comprehension")
      understandingLevelValues[3] += 1;
    else if (studentObjective.understanding_level === "Full")
      understandingLevelValues[2] += 1;
    else if (studentObjective.understanding_level === "Cursory")
      understandingLevelValues[1] += 1;
    else understandingLevelValues[0] += 1;
  }

  for (let i = 0; i < understandingLevelValues.length; i++) {
    understandingLevelValues[i] = parseFloat(
      ((understandingLevelValues[i] / totalStudents) * 100).toFixed(1),
    );
  }
  const labels = ["Some", "Cursory", "Full", "None"];
  const circles = calculateCirclePositions(understandingLevelValues).map(
    (el, index) => {
      return {
        ...el,
        label: `${understandingLevelValues[index]}%\n${labels[index]}`,
      };
    },
  );

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [hoveredCircle, setHoveredCircle] = useState<number | null>(null);
  const [tooltip, setTooltip] = useState<{
    text: string;
    x: number;
    y: number;
  } | null>(null);

  const drawCircles = (ctx: CanvasRenderingContext2D) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    circles.forEach(({ x, y, r, label }, index) => {
      // Split the label into two lines (Example: Use '\n' for multiple lines)
      const [line1, line2] = label.split("\n");

      // Draw circle
      ctx.beginPath();
      ctx.arc(x, y, r, 0, Math.PI * 2);

      if (index === hoveredCircle) {
        ctx.fillStyle = "rgba(144, 238, 144, 0.8)"; // lightgreen with 80% opacity
        ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
        ctx.shadowBlur = 10;
      } else {
        ctx.fillStyle = "rgba(173, 216, 230, 0.8)"; // lightblue with 80% opacity
        ctx.shadowColor = "transparent";
        ctx.shadowBlur = 0;
      }

      ctx.fill();
      ctx.strokeStyle = "blue";
      ctx.lineWidth = index === hoveredCircle ? 3 : 1;
      ctx.stroke();

      // Draw the first line of text
      ctx.fillStyle = "black";
      ctx.font = "14px Arial";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(line1, x, y - 10); // Adjust y position for the first line

      // Draw the second line of text
      if (line2) {
        ctx.fillText(line2, x, y + 10); // Adjust y position for the second line
      }
    });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        // Adjust for high-DPI screens
        const dpr = window.devicePixelRatio || 1;
        const width = canvas.clientWidth;
        const height = canvas.clientHeight;

        canvas.width = width * dpr;
        canvas.height = height * dpr;
        ctx.scale(dpr, dpr);

        // Draw circles with text
        drawCircles(ctx);
      }
    }
  }, [hoveredCircle, circles]);

  const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      // the mouse is within a specific circle if the distance from the center of the circle
      // to the cursor is less than the radius of the circle
      const foundCircleIndex = circles.findIndex(
        ({ x, y, r }) => Math.sqrt((mouseX - x) ** 2 + (mouseY - y) ** 2) <= r,
      );

      if (foundCircleIndex !== -1) {
        setHoveredCircle(foundCircleIndex);
        setTooltip({
          text: circles[foundCircleIndex].label,
          x: mouseX,
          y: mouseY,
        });
      } else {
        setHoveredCircle(null);
        setTooltip(null);
      }
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <canvas
        ref={canvasRef}
        style={{ width: `${canvasWidth}px`, height: `${canvasHeight}px` }}
        onMouseMove={handleMouseMove}
        onMouseLeave={() => {
          setHoveredCircle(null);
          setTooltip(null);
        }}
      />
      {tooltip && (
        <div
          style={{
            position: "absolute",
            left: tooltip.x + 10,
            top: tooltip.y + 10,
            backgroundColor: "white",
            border: "1px solid gray",
            borderRadius: "4px",
            padding: "4px",
            pointerEvents: "none",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
          }}
        >
          {tooltip.text}
        </div>
      )}
    </div>
  );
};

export default ObjectiveBubbleChart;
