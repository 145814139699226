import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  Box,
  MenuItem,
  Select,
  FormControl,
  TextField,
} from "@mui/material";
import moment from "moment";
import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import {
  setCurrentClassroomId,
  setCurrentClassroomTeacherId,
  setSelectedCourse,
} from "reducers/user";
import InputWithLabel from "components/InputWithLabel";
import ParagraphSection from "components/ParagraphSection";
import UploadMaterialModal from "components/modals/UploadMaterialModal";
import Insight from "components/teacher/Insight";
import DeleteModal from "components/modals/DeleteModal";
import { Add } from "@mui/icons-material";
import MaterialItem from "components/teacher/MaterialItem";
import { AxiosResponse } from "axios";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const CreateCourse = (props: any) => {
  const {
    userId,
    selectedCourse,
    currentSchoolId,
    currentClassroomTeacherId,
  }: {
    userId: string;
    selectedCourse?: DBCourse;
    currentSchoolId: string;
    currentClassroomTeacherId: string;
  } = props;
  const history = useHistory();

  const [deleteCourseModalOpen, setDeleteCourseModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [grade, setGrade] = useState("");
  const [materials, setMaterials] = useState<Array<any>>([]);
  const [materialDialogOpen, setMaterialDialogOpen] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState();
  const [startDate, setStartDate] = useState<Date | null>(
    selectedCourse?.startDate ? new Date(selectedCourse?.startDate) : null,
  );
  const [endDate, setEndDate] = useState<Date | null>(
    selectedCourse?.endDate ? new Date(selectedCourse?.endDate) : null,
  );

  const grades = [5, 6, 7, 8, 9, 10, 11, 12, "University", "Adult", "Other"];

  const toggleMaterialDialogOpen = () =>
    setMaterialDialogOpen(!materialDialogOpen);

  const handleSubmit = async () => {
    const gradeValue = typeof grade === "number" ? `Grade ${grade}` : grade;

    setLoading(true);
    try {
      const data: {
        schoolId: string;
        classroomTeacherId?: string;
        name: string;
        description: string;
        grade: string;
        materials: any[];
        courseId?: string;
        startDate: Date | null;
        endDate: Date | null;
      } = {
        schoolId: currentSchoolId,
        classroomTeacherId: selectedCourse && currentClassroomTeacherId,
        name,
        description,
        grade: gradeValue,
        materials,
        startDate,
        endDate,
        courseId: undefined,
      };

      if (!selectedCourse) {
        const classroomResponse = await api.classroom.create({
          schoolId: Number(currentSchoolId),
          name,
          startDate: moment().format("YYYY-MM-DD hh:mm:ss"),
          endDate: moment().add(1, "year").format("YYYY-MM-DD hh:mm:ss"),
          maxPupils: 1000,
          teachers: [
            {
              teachersId: Number(userId),
              subject: gradeValue,
            },
          ],
        });

        const { classroom, teachers } = classroomResponse.data;
        props.setCurrentClassroomId(classroom.id);
        props.setCurrentClassroomTeacherId(teachers[0].classroomTeacherId);

        data.classroomTeacherId = teachers[0].classroomTeacherId.toString();
        data.materials = materials;
      }

      if (selectedCourse) data.courseId = selectedCourse.id!.toString();

      let courseResponse: AxiosResponse<CourseResponse, any>;
      if (selectedCourse && data.courseId) {
        courseResponse = await api.course.update({
          courseId: data.courseId,
          description: data.description,
          grade: data.grade,
          name: data.name,
          startDate: moment(startDate).format("YYYY-MM-DD hh:mm:ss"),
          endDate: moment(endDate).format("YYYY-MM-DD hh:mm:ss"),
        });
      } else {
        if (!data.classroomTeacherId)
          throw new Error(
            "Unable to create course. Expected a classroom teacher ID but got none.",
          );
        courseResponse = await api.course.create({
          classroomTeacherId: Number(data.classroomTeacherId!),
          description: data.description,
          grade: data.grade,
          name: data.name,
          startDate: moment(startDate).format("YYYY-MM-DD hh:mm:ss"),
          endDate: moment(endDate).format("YYYY-MM-DD hh:mm:ss"),
        });
        const materialsToCreate = materials.map((material) => {
          material.coursesId = courseResponse.data.course.id;
          return material;
        });

        await Promise.all(
          materialsToCreate.map((material) =>
            api.material.create({ material }),
          ),
        );
      }

      const course = courseResponse.data.course;
      props.setSelectedCourse(course);
      history.push(`/class/${course.id}`);
    } catch (e: any) {
      props.setSnackbar({ open: true, message: e.message, severity: "error" });
    } finally {
      setLoading(false);
    }
  };

  // Added refs for the date pickers
  const [startFocused, setStartFocused] = useState(false);
  const [endFocused, setEndFocused] = useState(false);

  const startDatePickerRef = useRef<HTMLInputElement>(null);
  const endDatePickerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const startInput = startDatePickerRef.current;
    if (!startInput) return;
    const handleFocus = () => setStartFocused(true);
    const handleBlur = () => setStartFocused(false);
    startInput.addEventListener("focus", handleFocus);
    startInput.addEventListener("blur", handleBlur);
    return () => {
      startInput.removeEventListener("focus", handleFocus);
      startInput.removeEventListener("blur", handleBlur);
    };
  }, []);

  useEffect(() => {
    const endInput = endDatePickerRef.current;
    if (!endInput) return;
    const handleFocus = () => setEndFocused(true);
    const handleBlur = () => setEndFocused(false);
    endInput.addEventListener("focus", handleFocus);
    endInput.addEventListener("blur", handleBlur);
    return () => {
      endInput.removeEventListener("focus", handleFocus);
      endInput.removeEventListener("blur", handleBlur);
    };
  }, []);

  useEffect(() => {
    setName(selectedCourse?.name || "");
    setDescription(selectedCourse?.description || "");
    setGrade(selectedCourse?.grade || "");
    setStartDate(
      selectedCourse?.startDate ? new Date(selectedCourse.startDate) : null,
    );
    setStartDate(
      selectedCourse?.endDate ? new Date(selectedCourse.endDate) : null,
    );
    const fetchCourse = async () => {
      const course = await api.course.get({
        courseId: selectedCourse!.id!.toString(),
      });
      setMaterials(course.data.materials);
    };
    if (!selectedCourse?.id) setMaterials([]);
    else fetchCourse();
    // eslint-disable-next-line
  }, [selectedCourse]);

  useEffect(() => {
    if (!startDate || !endDate) return;
    if (startFocused || endFocused) return;
    if (startDate > endDate) {
      props.setSnackbar({
        open: true,
        severity: "error",
        message: "Start date cannot be after the end date.",
      });
    }
  }, [startDate, endDate, endFocused, startFocused]);

  return (
    <>
      <Grid
        container
        sx={{ pt: 4, px: 24, mb: 4, justifyContent: "center", gap: 8 }}
        spacing={4}
      >
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            p: "0 !important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              pb: 4,
              pt: 8,
            }}
          >
            <Typography fontFamily="Inter">
              Ellie works best with context — provide as much information as you
              can to guide your virtual assistant.
            </Typography>
          </Box>
          <Box
            sx={{
              gap: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputWithLabel
                  label="Class Name"
                  placeholder={"Economy 101 Sect A"}
                  value={name}
                  handleValueChange={(e: any) => setName(e.target.value)}
                  isRequired
                />
              </Grid>
              <Grid item xs={12}>
                <InputWithLabel
                  label="Class Description"
                  placeholder={
                    "A 10th grade introduction to the basic tools of Economics. Here you will learn about the general market values, inflation, and more."
                  }
                  multiline
                  value={description}
                  handleValueChange={(e: any) => setDescription(e.target.value)}
                  isRequired
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      marginBottom: 2,
                      position: "relative",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: "19.36px",
                        letterSpacing: "0.02em",
                        textAlign: "left",
                        display: "flex",
                        marginBottom: "8px",
                      }}
                    >
                      Class Grade
                      <Typography
                        sx={{
                          color: "primary.main",
                          fontSize: "12px",
                          lineHeight: "14.63px",
                          letterSpacing: "0.02em",
                          ml: 1,
                        }}
                      >
                        *
                      </Typography>
                    </Typography>
                    <Select
                      labelId="grade-select-label"
                      value={grade}
                      onChange={(e) => setGrade(e.target.value)}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected === "") {
                          return (
                            <span
                              style={{ color: "#000000", fontStyle: "italic" }}
                            >
                              Select a grade
                            </span>
                          );
                        }
                        return typeof selected === "number"
                          ? `Grade ${selected}`
                          : selected;
                      }}
                      fullWidth
                      sx={{
                        fontFamily: "Inter",
                        backgroundColor: "white",
                        "& fieldset": { border: "none" },
                      }}
                    >
                      <MenuItem
                        value=""
                        sx={{
                          fontFamily: "Inter",
                          color: "#000000",
                          fontStyle: "italic",
                        }}
                      >
                        Select a grade
                      </MenuItem>
                      {grades.map((grade) => (
                        <MenuItem
                          key={grade}
                          value={grade}
                          sx={{ fontFamily: "Inter" }}
                        >
                          {typeof grade === "number" ? `Grade ${grade}` : grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "19.36px",
                    letterSpacing: "0.02em",
                    textAlign: "left",
                    display: "flex",
                    marginBottom: "8px",
                  }}
                >
                  Start Date
                  <Typography
                    sx={{
                      color: "primary.main",
                      fontSize: "12px",
                      lineHeight: "14.63px",
                      letterSpacing: "0.02em",
                      ml: 1,
                    }}
                  >
                    *
                  </Typography>
                </Typography>
                <DatePicker
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-disabled fieldset": {
                        borderColor: "white",
                      },
                    },
                  }}
                  label="Start Date"
                  defaultValue={
                    endDate ? moment(selectedCourse?.startDate) : null
                  }
                  onChange={(value) => {
                    setStartDate(value?.toDate() ?? null);
                  }}
                  inputRef={startDatePickerRef}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "19.36px",
                    letterSpacing: "0.02em",
                    textAlign: "left",
                    display: "flex",
                    marginBottom: "8px",
                  }}
                >
                  End Date
                  <Typography
                    sx={{
                      color: "primary.main",
                      fontSize: "12px",
                      lineHeight: "14.63px",
                      letterSpacing: "0.02em",
                      ml: 1,
                    }}
                  >
                    *
                  </Typography>
                </Typography>
                <DatePicker
                  inputRef={endDatePickerRef}
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-disabled fieldset": {
                        borderColor: "white",
                      },
                    },
                  }}
                  label="End Date"
                  defaultValue={
                    startDate ? moment(selectedCourse?.endDate) : null
                  }
                  onChange={(value) => {
                    setEndDate(value?.toDate() ?? null);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            pb: 4,
            pt: 17.5,
            flexGrow: 1,
            width: "40%",
          }}
        >
          <Typography fontWeight={"bold"}>Class Materials</Typography>
          {materials
            .filter((el) => !el.activityId)
            .map((el, i) => {
              return (
                <MaterialItem
                  key={`material-course-create-${i}`}
                  material={el}
                  onRemove={() => {
                    setMaterials((materials) =>
                      materials.filter((_, index) => index !== i),
                    );
                  }}
                  onClick={() => {
                    return;
                  }}
                />
              );
            })}
          <Button
            variant="outlined"
            onClick={toggleMaterialDialogOpen}
            startIcon={<Add />}
            sx={{
              width: "fit-content",
              borderRadius: 1,
            }}
          >
            <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
              Add Materials
            </Typography>
          </Button>
          {!selectedCourse?.id && (
            <Insight title="TIPS">
              <ParagraphSection
                title="Class Title"
                description=" Provide a concise title."
              />
              <ParagraphSection
                title="Class Description"
                description=" Write a brief description of the class. Include any relevant details the students need to know."
              />
              <ParagraphSection
                title="Class Date"
                description="This date range will help us auto archive your classes, so then you can view it in your class library."
              />
            </Insight>
          )}
        </Box>
        <UploadMaterialModal
          setMaterials={setMaterials}
          concatMaterials={(newMaterials: any[]) =>
            setMaterials((materials) => [...materials, ...newMaterials])
          }
          selectedMaterial={selectedMaterial}
          setSelectedMaterial={setSelectedMaterial}
          courseId={selectedCourse?.id}
          open={materialDialogOpen}
          onClose={toggleMaterialDialogOpen}
        />
        <DeleteModal
          open={deleteCourseModalOpen}
          setOpen={setDeleteCourseModalOpen}
          itemTitle={selectedCourse?.name}
          onClose={() => {
            setDeleteCourseModalOpen(false);
          }}
          deleteFunction={async () => {
            setDeleteCourseModalOpen(false);
            if (!selectedCourse?.id)
              throw new Error(
                "Unable to delete course. Expected a course id but got none",
              );
            await api.course.delete({
              courseId: selectedCourse.id.toString(),
            });

            history.push("/classes");
          }}
        />
      </Grid>
      <Box sx={{ px: 12, gap: 2 }}>
        <Box
          sx={{
            width: "100%",
            height: 2,
            backgroundColor: "background.sideNav",
            my: 2,
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
          {selectedCourse && (
            <Button
              variant="outlined"
              color="error"
              sx={{
                alignSelf: "start",
                width: "fit-content",
                borderRadius: 1,
              }}
              onClick={() => {
                setDeleteCourseModalOpen(true);
              }}
            >
              <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
                Delete Course
              </Typography>
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={() => history.goBack()}
            sx={{ borderRadius: 1 }}
          >
            <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
              Cancel
            </Typography>
          </Button>
          <Button
            disabled={
              loading ||
              !name ||
              !description ||
              !grade ||
              !startDate ||
              !endDate ||
              startDate > endDate
            }
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            sx={{ borderRadius: 1 }}
          >
            <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
              {selectedCourse ? "Save" : "Next"}
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userId: state.user.id,
  selectedCourse: state.user.selectedCourse,
  currentSchoolId: state.user.currentSchoolId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
});

const mapDispatchToProps = {
  setCurrentClassroomId,
  setCurrentClassroomTeacherId,
  setSelectedCourse,
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCourse);
