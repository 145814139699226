import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { Add, Check, Clear, Delete } from "@mui/icons-material";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import Modal from "components/Modal";
import ClassroomTeacherSelector from "components/selectors/ClassroomTeacherSelector";
import StudentSelector from "components/selectors/StudentSelector";

const EditClassroomUsersModal = (props: any) => {
  const { selectedClassroom, schoolId, open, onClose } = props;

  const [pupils, setPupils] = useState<Array<any>>([]);
  const [removePupils, setRemovePupils] = useState<Array<any>>([]);
  const [teachers, setTeachers] = useState<Array<any>>([]);
  const [removeTeachers, setRemoveTeachers] = useState<Array<any>>([]);

  const onSubmit = async () => {
    try {
      await api.classroom.updatePupils(
        {
          classroomId: selectedClassroom.id,
          pupils: pupils
            .filter((pupil) => Object(pupil).hasOwnProperty("new"))
            .map((pupil) => pupil.id),
          teachers: teachers
            .filter((teacher) => Object(teacher).hasOwnProperty("new"))
            .map((teacher) => ({
              teachersId: teacher.id,
              subject: teacher.subject,
            })),
          removePupils,
          removeTeachers,
        },
      );

      setPupils([]);
      setRemovePupils([]);
      setTeachers([]);
      setRemoveTeachers([]);

      onClose();
    } catch (error: any) {
      props.setSnackbar({
        open: true,
        severity: "error",
        message: error.message,
      });
    }
  };

  useEffect(() => {
    setPupils(selectedClassroom?.pupils || []);
    setRemovePupils([]);
    setTeachers(selectedClassroom?.teachers || []);
    setRemoveTeachers([]);
  }, [selectedClassroom]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      title="Manage Teachers & Students"
      submitText="Save"
      cancelText="Cancel"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: 400,
          padding: 2,
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1" fontWeight="bold">
            Teachers
          </Typography>
          {teachers && teachers[teachers.length - 1]?.new !== true && (
            <IconButton
              onClick={() =>
                setTeachers([...teachers, { subject: "", new: true }])
              }
            >
              <Add color="primary" />
            </IconButton>
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          {teachers.map((teacher, index) =>
            !teacher.new ? (
              <Box
                key={teacher.id}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  border: "1px solid lightgray",
                  borderRadius: 2,
                  px: 2,
                  py: 1,
                  boxShadow: "0 0 4px lightgray",
                }}
              >
                <Box>
                  <Typography variant="body2">
                    {teacher.firstName} {teacher.lastName}
                  </Typography>
                  <Typography variant="caption">{teacher.subject}</Typography>
                </Box>
                {teachers.length > 1 && (
                  <IconButton
                    onClick={() => {
                      setTeachers(teachers.filter((_, i) => i !== index));
                      setRemoveTeachers([...removeTeachers, teacher.id]);
                    }}
                  >
                    <Delete color="disabled" />
                  </IconButton>
                )}
              </Box>
            ) : (
              <Box
                display="flex"
                gap={2}
                border="1px dashed lightgray"
                borderRadius={2}
                p={1}
                py={2}
              >
                <Box display="flex" flexDirection="column" gap={2} flex={1}>
                  <ClassroomTeacherSelector
                    schoolId={schoolId}
                    wholeUser
                    filterOut={teachers.map((teacher) => {
                      if (!removeTeachers.includes(teacher.id))
                        return teacher.id;
                      else return null;
                    })}
                    setClassroomTeacherId={(value: any) => {
                      const updatedTeachers = teachers.map((teacher, i) =>
                        i === index ? { ...teacher, ...value } : teacher,
                      );
                      setTeachers(updatedTeachers);
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Subject"
                    value={teacher.subject}
                    onChange={(e) => {
                      const updatedTeachers = teachers.map((teacher, i) =>
                        i === index
                          ? { ...teacher, subject: e.target.value }
                          : teacher,
                      );
                      setTeachers(updatedTeachers);
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <IconButton
                    onClick={() => {
                      setTeachers(teachers.filter((_, i) => i !== index));
                    }}
                  >
                    <Clear color="error" />
                  </IconButton>
                  <IconButton
                    disabled={!teacher.id || !teacher.subject}
                    onClick={() => {
                      setTeachers(
                        teachers.map((teacher, i) => {
                          if (i === index) {
                            return { ...teacher, new: false };
                          } else return teacher;
                        }),
                      );
                    }}
                  >
                    <Check
                      color={
                        !teacher.id || !teacher.subject ? "disabled" : "success"
                      }
                    />
                  </IconButton>
                </Box>
              </Box>
            ),
          )}
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1" fontWeight="bold">
            Students
          </Typography>
          {pupils && pupils[pupils.length - 1]?.new !== true && (
            <IconButton onClick={() => setPupils([...pupils, { new: true }])}>
              <Add color="primary" />
            </IconButton>
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          {pupils.map((pupil, index) =>
            !pupil.new ? (
              <Box
                key={pupil.id}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  border: "1px solid lightgray",
                  borderRadius: 2,
                  px: 2,
                  py: 1,
                  boxShadow: "0 0 4px lightgray",
                }}
              >
                <Box>
                  <Typography variant="body2">
                    {pupil.firstName} {pupil.lastName}
                  </Typography>
                </Box>
                {pupils.length > 1 && (
                  <IconButton
                    onClick={() => {
                      setPupils(pupils.filter((_, i) => i !== index));
                      setRemovePupils([...removePupils, pupil.id]);
                    }}
                  >
                    <Delete color="disabled" />
                  </IconButton>
                )}
              </Box>
            ) : (
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                border="1px dashed lightgray"
                borderRadius={2}
                p={1}
                py={2}
              >
                <StudentSelector
                  schoolId={schoolId}
                  wholeUser
                  filterOut={pupils.map((pupil) => {
                    if (!removePupils.includes(pupil.id)) return pupil.id;
                    else return null;
                  })}
                  setStudentId={(value: any) => {
                    const updatedPupils = pupils.map((pupil, i) =>
                      i === index ? { ...pupil, ...value } : pupil,
                    );
                    setPupils(updatedPupils);
                  }}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <IconButton
                    onClick={() => {
                      setPupils(pupils.filter((_, i) => i !== index));
                    }}
                  >
                    <Clear color="error" />
                  </IconButton>
                  <IconButton
                    disabled={!pupil.id}
                    onClick={() => {
                      setPupils(
                        pupils.map((pupil, i) => {
                          if (i === index) {
                            return { ...pupil, new: false };
                          } else return pupil;
                        }),
                      );
                    }}
                  >
                    <Check color={!pupil.id ? "disabled" : "success"} />
                  </IconButton>
                </Box>
              </Box>
            ),
          )}
        </Box>
      </Box>
    </Modal>
  );
};

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(null, mapDispatchToProps)(EditClassroomUsersModal);
