import { cookieAuthInstance } from "lib/api/utils";

const createCourseActivity = (activity: ActivityCreateRequest) => {
  return cookieAuthInstance.post<DBCourseActivity>("/activities/", activity);
};

const activityGetAnalytics = ({
  activityId,
  pupilId,
}: {
  activityId: string;
  pupilId: string;
}) => {
  return cookieAuthInstance.get<LatestObjectivesSummaryResponse>(
    `/activities/${activityId}/analytics/${pupilId}`,
  );
};

const activityGetBatchAnalytics = ({
  activityId,
  pupilIds,
}: {
  activityId: string;
  pupilIds: number[];
}) => {
  return cookieAuthInstance.post<BatchAnalyticsResponse>(
    `/activities/${activityId}/analytics/batchObjAnalytics`,
    pupilIds,
  );
};

const activityGetSentiments = ({
  activityId,
  pupilId,
}: {
  activityId: string;
  pupilId: string;
}) => {
  return cookieAuthInstance.get<CourseActivityObjectiveSentimentAnalyticsResponse>(
    `/activities/${activityId}/sentiment_analytics/${pupilId}`,
  );
};

const activityGetBatchSentiments = ({
  activityId,
  pupilIds,
}: {
  activityId: string;
  pupilIds: number[];
}) => {
  return cookieAuthInstance.post<{
    analytics: {
      [pupilId: number]: CourseActivityObjectiveSentimentAnalyticsResponse;
    };
  }>(`/activities/${activityId}/sentiment_analytics/batchSentiments`, pupilIds);
};

const activityGenerateKnowledgeMap = ({
  activityId,
  objectiveIds,
}: {
  activityId: string;
  objectiveIds?: number[];
}) => {
  return cookieAuthInstance.post<{
    message: string;
    activity_id: number;
    objectives_processed: number;
  }>(`/activities/${activityId}/knowledge-maps`, {
    objective_ids: objectiveIds,
  });
};

const activities = {
  createActivity: createCourseActivity,
  getAnalytics: activityGetAnalytics,
  getSentiments: activityGetSentiments,
  getBatchAnalytics: activityGetBatchAnalytics,
  getBatchSentiments: activityGetBatchSentiments,
  generateMap: activityGenerateKnowledgeMap,
};

export default activities;
