import { Pause, PlayArrow } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import api from "lib/api";
import { useState } from "react";
import { connect } from "react-redux";
import { setSnackbar } from "reducers/ui";
import { setSelectedActivity } from "reducers/user";

function ToggleActivityButton(props: any) {
  const { activity, setSelectedActivity } = props;
  const [activityEnabled, setActivityEnabled] = useState(
    activity.activity.enabled,
  );
  return (
    <>
      <Box>
        <Button
          variant="contained"
          startIcon={activityEnabled ? <Pause /> : <PlayArrow />}
          sx={{
            fontFamily: "Inter",
            padding: 1,
            lineHeight: "16px",
            borderRadius: 1,
            fontSize: "18px",
          }}
          onClick={async () => {
            api.activity
              .toggle(
                { activityId: activity.activity.id, enabled: !activityEnabled },
              )
              .then(() => {
                setActivityEnabled(!activityEnabled);
                const activityCopy = { ...activity };
                activityCopy.activity = { ...activityCopy.activity };
                activityCopy.activity.enabled = !activityEnabled;
                setSelectedActivity(activityCopy);
                props.setSnackbar({
                  open: true,
                  severity: "success",
                  message: `Successfully ${!activityEnabled ? "enabled" : "disabled"} activity`,
                });
              })
              .catch((e) => {
                console.error(e);
                props.setSnackbar({
                  open: true,
                  severity: "error",
                  message: `Failed to ${!activityEnabled ? "enabled" : "disabled"} activity`,
                });
              });
          }}
        >
          {activityEnabled ? "Pause Activity" : "Start Activity"}
        </Button>
      </Box>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  activity: state.user.selectedActivity,
});

const mapDispatchToProps = {
  setSnackbar,
  setSelectedActivity,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToggleActivityButton);
