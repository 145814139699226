import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, TextField } from "@mui/material";
import styled from "@emotion/styled";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import Modal from "components/Modal";
import Avatar from "components/Avatar";
import { setUser } from "reducers/user";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const EditUserModal = (props: any) => {
  const { user, selectedUser, open, onClose } = props;

  const [email, setEmail] = useState("");
  const [preview, setPreview] = useState<string | null>(null);
  const [profileImageLink, setProfileImageLink] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOnChangeFile = async (e: any) => {
    const newFile = e?.target?.files[0];

    if (newFile) {
      setPreview(URL.createObjectURL(newFile));
      const formData = new FormData();
      formData.append("files", newFile);

      setLoading(true);
      try {
        const response = await api.media.upload(
          {
            schoolId: user.currentSchoolId,
            formData,
          },
        );

        setProfileImageLink(response.data.links[0].link);
      } catch (e: any) {
        props.setSnackbar({
          open: true,
          severity: "error",
          message: e.message,
        });
      } finally {
        setLoading(false);
      }
    } else setPreview(null);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);

      if (user.role === "teacher" || user.role === "pupil") {
        const response = await api.user.update(
          {
            email,
            profileImageLink,
            firstName,
            lastName,
          },
        );

        props.setUser({ ...user, ...response.data });
      } else {
        await api.user.masterUpdate(
          {
            userId: selectedUser.id,
            email,
            firstName,
            lastName,
            profileImageLink,
          },
        );
      }

      props.setSnackbar({
        open: true,
        severity: "success",
        message: "Profile updated successfully",
      });
    } catch (error: any) {
      props.setSnackbar({
        open: true,
        severity: "error",
        message: error.message,
      });
    } finally {
      setLoading(false);
      setEmail("");
      setProfileImageLink("");
      setFirstName("");
      setLastName("");
      onClose();
    }
  };

  useEffect(() => {
    setEmail(selectedUser?.email || "");
    setProfileImageLink(selectedUser?.profileImageLink || "");
    setFirstName(selectedUser?.firstName || "");
    setLastName(selectedUser?.lastName || "");
  }, [selectedUser]);

  useEffect(() => {
    setPreview(null);
  }, [open]);

  return (
    <Modal
      disabled={loading}
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      title={
        user.role === "teacher" || user.role === "pupil"
          ? "Edit Profile"
          : "Edit User"
      }
      submitText="Save"
      cancelText="Cancel"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: 400,
          padding: 2,
        }}
      >
        {(user.role === "teacher" || user.role === "pupil") && (
          <Box sx={{ display: "flex", justifyContent: "center", mb: 6 }}>
            <Box
              sx={{
                cursor: "pointer",
                transition: "opacity 0.5s",
                "&:hover": {
                  opacity: 0.5,
                  transition: "opacity 0.5s",
                },
              }}
              component="label"
              role={undefined}
              tabIndex={-1}
            >
              <Avatar preview={preview} sx={{ width: 100, height: 100 }} />
              <VisuallyHiddenInput
                disabled={loading}
                onChange={handleOnChangeFile}
                type="file"
              />
            </Box>
          </Box>
        )}
        <TextField
          fullWidth
          label="Email"
          type="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          fullWidth
          label="First Name"
          autoComplete="given-name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          fullWidth
          label="Last Name"
          autoComplete="family-name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
});

const mapDispatchToProps = {
  setUser,
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserModal);
