import { cookieAuthInstance, createAuthHeaders } from "lib/api/utils";
const materialUpdate = ({ material }: MaterialUpdateRequest) => {
  return cookieAuthInstance.patch<MaterialResponse>("/materials/", {
    material,
  });
};

const materialCreate = ({ material }: { material: MaterialCreateRequest }) => {
  return cookieAuthInstance.post<MaterialResponse>("/materials/", material);
};

const materialDelete = ({ materialId }: { materialId: string }) => {
  return cookieAuthInstance.delete<MaterialResponse>(
    `/materials/${materialId}`,
  );
};

const material = {
  update: materialUpdate,
  create: materialCreate,
  delete: materialDelete,
};

export default material;
