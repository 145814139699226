import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, TableCell, Typography } from "@mui/material";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import EnhancedTable from "components/DataTable";
import SchoolSelector from "components/selectors/SchoolSelector";
import ClassroomTeacherSelector from "components/selectors/ClassroomTeacherSelector";
import EditCourseModal from "components/modals/EditCourseModal";

const CoursePage = (props: any) => {
  const [schoolId, setSchoolId] = useState();
  const [classroomTeacherId, setClassroomTeacherId] = useState();
  const [courses, setCourses] = useState([] as any[]);
  const [editCourseModalOpen, setEditCourseModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const toggleEditCourseModalOpen = () =>
    setEditCourseModalOpen(!editCourseModalOpen);

  const rowCells = (row: any) => (
    <>
      <TableCell align="left">{row.id}</TableCell>
      <TableCell align="right">{row.classroomsTeachersId}</TableCell>
      <TableCell align="right">{row.name}</TableCell>
      <TableCell align="right">{row.description}</TableCell>
      <TableCell align="right">{row.grade}</TableCell>
    </>
  );

  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "ID",
    },
    {
      id: "classroomsTeachersId",
      numeric: true,
      disablePadding: false,
      label: "Classroom Teacher's ID",
    },
    {
      id: "name",
      numeric: true,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "description",
      numeric: true,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "grade",
      numeric: true,
      disablePadding: false,
      label: "Grade",
    },
  ];

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        if (!classroomTeacherId)
          throw new Error(
            "Unable to fetch courses. Expected classroom id and got none",
          );
        const response = await api.classroom.listCourses(
          {
            classroomTeacherId,
            page: page + 1,
            rowsPerPage,
          });
        if (response.data?.courses?.length === 0 && page > 0)
          setPage((page) => page - 1);
        else if (
          response.data?.courses?.length > 0 &&
          !(courses.length > rowsPerPage * page)
        )
          if (page > 0)
            setCourses((courses) =>
              [
                ...courses,
                ...response.data.courses.map((item: any) => item.course),
              ].filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.id === value.id),
              ),
            );
          else
            setCourses(response.data.courses.map((item: any) => item.course));
        else if (response.data?.courses?.length > 0 && page === 0)
          setCourses(response.data.courses.map((item: any) => item.course));
      } catch (error: any) {
        props.setSnackbar({
          open: true,
          message: `Error fetching courses: ${error.message}`,
          severity: "error",
        });
      }
    };

    if (schoolId && classroomTeacherId && !editCourseModalOpen) fetchCourses();

    if (schoolId && !classroomTeacherId) setCourses([]);
    // eslint-disable-next-line
  }, [schoolId, classroomTeacherId, editCourseModalOpen, page, rowsPerPage]);

  return (
    <Box>
      <Typography variant="h4" textAlign="center" mb={2}>
        Courses
      </Typography>

      {schoolId && classroomTeacherId && (
        <Box
          sx={{
            display: "flex",
            marginBottom: 2,
          }}
        >
          <Box display="flex" gap={2}>
            <Box width={300}>
              <SchoolSelector
                clear={() => setCourses([])}
                schoolId={schoolId}
                setSchoolId={setSchoolId}
              />
            </Box>
            <Box width={300}>
              <ClassroomTeacherSelector
                classroomTeacherIds
                schoolId={schoolId}
                classroomTeacherId={classroomTeacherId}
                setClassroomTeacherId={setClassroomTeacherId}
              />
            </Box>
          </Box>
        </Box>
      )}

      {schoolId && classroomTeacherId ? (
        <EnhancedTable
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          filterDisabled
          selectionDisabled
          rows={courses}
          rowCells={rowCells}
          headCells={headCells}
          onRowClick={(course: any) => {
            setSelectedCourse(course);
            toggleEditCourseModalOpen();
          }}
        />
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h5" textAlign="center" mb={2}>
            Select a school and a teacher to view courses
          </Typography>

          <Box width={400} display="flex" flexDirection="column" gap={2}>
            <SchoolSelector
              clear={() => setCourses([])}
              schoolId={schoolId}
              setSchoolId={setSchoolId}
            />
            <ClassroomTeacherSelector
              disabled={!schoolId}
              classroomTeacherIds
              schoolId={schoolId}
              classroomTeacherId={classroomTeacherId}
              setClassroomTeacherId={setClassroomTeacherId}
            />
          </Box>
        </Box>
      )}

      <EditCourseModal
        selectedCourse={selectedCourse}
        classroomTeacherId={classroomTeacherId}
        schoolId={schoolId}
        open={editCourseModalOpen}
        onClose={toggleEditCourseModalOpen}
      />
    </Box>
  );
};

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(null, mapDispatchToProps)(CoursePage);
