import api from "lib/api";

/**
 * Handles user logout by clearing auth cookie, local storage, Redux state, and Auth0 session
 * @param {Function} logout - Auth0 logout function
 * @param {Function} clearUser - Redux action to clear user state
 * @returns {Promise<void>}
 *
 * @example
 * // Usage with Auth0 and Redux
 * await handleUserLogout(logout, clearUser);
 *
 * @throws {Error} If logout fails
 */
export const handleUserLogout = async (logout: any, clearUser: any) => {
  try {
    // call backend to clear cookie
    await api.user.auth.logout();

    // clear local storage
    localStorage.clear();

    // clear redux state
    clearUser();

    // finally, logout from Auth0
    logout({
      logoutParams: {
        returnTo: window.location.origin,
        federated: false,
      },
    });
  } catch (error) {
    console.error("Logout failed:", error);
    localStorage.clear();
    clearUser();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
        federated: false,
      },
    });
  }
};
