import React from "react";
import { Box } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import SentimentLabels from "./SentimentLabels";
import findMaxEmotion from "utils/findMaxEmotion";
import theme from "theme";

const SentimentPieChart = ({
  students,
}: {
  students: Array<{
    id: any;
    name: string;
    firstName: any;
    lastName: any;
    email: any;
    profileImageLink: any;
    notes: string;
    analytics: LatestObjectivesSummaryResponse[] | null;
    sentiment_analytics: CourseActivityObjectiveSentimentAnalytics[];
  }>;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        alignItems: "center",
        px: 2,
        width: 350,
      }}
    >
      <Box>
        <PieChart
          series={[
            {
              data: [
                {
                  id: "excited",
                  value: students.filter(
                    (student) => findMaxEmotion(student) === "Excited",
                  ).length,
                  color: theme.palette.graph!.excited,
                },
                {
                  id: "curious",
                  value: students.filter(
                    (student) => findMaxEmotion(student) === "Curious",
                  ).length,
                  color: theme.palette.graph!.curious,
                },
                {
                  id: "confused",
                  value: students.filter(
                    (student) => findMaxEmotion(student) === "Confused",
                  ).length,
                  color: theme.palette.graph!.confused,
                },
                {
                  id: "bored",
                  value: students.filter(
                    (student) => findMaxEmotion(student) === "Bored",
                  ).length,
                  color: theme.palette.graph!.bored,
                },
                {
                  id: "frustrated",
                  value: students.filter(
                    (student) => findMaxEmotion(student) === "Frustrated",
                  ).length,
                  color: theme.palette.graph!.frustrated,
                },
                {
                  id: "focused",
                  value: students.filter(
                    (student) => findMaxEmotion(student) === "Engaged",
                  ).length,
                  color: theme.palette.graph!.engaged,
                },
              ],
              highlightScope: { faded: "global", highlighted: "item" },
              faded: {
                innerRadius: 30,
                additionalRadius: -30,
                color: "gray",
              },
            },
          ]}
          height={300}
          width={300}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: 1,
          position: "absolute",
          right: 0,
        }}
      >
        <SentimentLabels label="Excited" />
        <SentimentLabels label="Engaged" />
        <SentimentLabels label="Curious" />
        <SentimentLabels label="Confused" />
        <SentimentLabels label="Frustrated" />
        <SentimentLabels label="Bored" />
      </Box>
    </Box>
  );
};

export default SentimentPieChart;
