import { cookieAuthInstance } from "lib/api/utils";

interface MetabaseEmbedResponse {
  embed_url: string;
}

const getCourseEmbedUrl = (dashboardId: number, courseId: string) => {
  return cookieAuthInstance.get<MetabaseEmbedResponse>(
    `/metabase/embed/dashboard/${dashboardId}`,
    {
      params: {
        course_id: courseId,
      },
    },
  );
};

const metabase = {
  getCourseEmbedUrl,
};

export default metabase;
