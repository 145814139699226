import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  Button,
  Skeleton,
} from "@mui/material";
import { Add } from "@mui/icons-material";

import { setSnackbar } from "reducers/ui";
import {
  setCurrentClassroomId,
  setCurrentClassroomTeacherId,
  setCurrentSchoolId,
  setSelectedActivity,
  setSelectedCourse,
} from "reducers/user";
import CourseItem from "components/CourseItem";
import { useCourses } from "hooks/useCourses";
import { useAuth0 } from "@auth0/auth0-react";

const CourseList = (props: any) => {
  const { userId, role, currentSchoolId } = props;
  const history = useHistory();
  const { user } = useAuth0();

  const [loading, setLoading] = useState(false);
  const courses = useCourses({
    role,
    userId,
    currentSchoolId,
    setCurrentSchoolId: props.setCurrentSchoolId,
    setCurrentClassroomTeacherId: props.setCurrentClassroomTeacherId,
    setCurrentClassroomId: props.setCurrentClassroomId,
    setSelectedCourse: props.setSelectedCourse,
    setSelectedActivity: props.setSelectedActivity,
    setSnackbar: props.setSnackbar,
    setLoading,
  });

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          width: "100%",
          height: "90vh",
          maxHeight: "90vh",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            width: 400,
            px: 2,
            pb: 3,
          }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            fontFamily="Inter"
            textAlign="start"
          >
            Hello {user?.nickname ?? user?.name ?? ""}!
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            width: 400,
            p: 2,
            mb: 1,
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            fontFamily="Inter"
            textAlign="start"
          >
            Current Classes
          </Typography>
        </Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {Array.from({ length: 6 }, (_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                animation={index % 2 === 0 ? "wave" : "pulse"}
                sx={{ width: 360, height: 50, borderRadius: 2 }}
              />
            ))}
            <CircularProgress
              sx={{ position: "absolute", opacity: 0.5 }}
              color="info"
              size={120}
            />
          </Box>
        ) : (
          <Box
            sx={{
              gap: 2,
              display: "flex",
              flexDirection: "column",
              maxHeight: "70vh",
              overflowY: "auto",
              maxWidth: 400,
              px: 2,
            }}
          >
            {courses?.map((item, index) => (
              <CourseItem key={index} course={item} noEdit />
            ))}
            <Button
              variant="contained"
              sx={{
                p: 1,
                mt: 2,
                width: "fit-content",
                borderRadius: 1,
                height: 30,
              }}
              onClick={() => {
                history.push("/class/create");
              }}
            >
              <Add fontSize="small" />
              <Typography
                sx={{ fontSize: 14, fontFamily: "Inter", fontWeight: "bold" }}
              >
                Add Class
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
  userId: state.user.id,
  role: state.user.role,
  currentSchoolId: state.user.currentSchoolId,
});

const mapDispatchToProps = {
  setCurrentSchoolId,
  setCurrentClassroomId,
  setCurrentClassroomTeacherId,
  setSelectedCourse,
  setSelectedActivity,
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseList);
