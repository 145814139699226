import { cookieAuthInstance } from "lib/api/utils";

const classroomCreate = ({
  schoolId,
  name,
  startDate,
  endDate,
  maxPupils,
  pupils,
  teachers,
}: ClassroomCreateRequest) => {
  return cookieAuthInstance.post<ClassroomResponse>(`/classrooms/`, {
    schoolId,
    name,
    startDate,
    endDate,
    maxPupils,
    pupils,
    teachers,
  });
};

const classroomGet = ({ classroomId }: { classroomId: string }) => {
  return cookieAuthInstance.get<ClassroomResponse>(
    `/classrooms/${classroomId}`,
  );
};

const classroomGetCode = ({ classroomId }: { classroomId: string }) => {
  return cookieAuthInstance.get<string>(`/classrooms/${classroomId}/code`);
};

const enrollStudentInClassroom = ({
  classroomCode,
  userId,
}: {
  classroomCode: string;
  userId: string;
}) => {
  return cookieAuthInstance.post<{
    message: "Enrollment successful";
    classroomId: number;
  }>(`/classrooms/${classroomCode}/enroll/${userId}`, {});
};

const courseList = ({
  classroomTeacherId,
  page = 1,
  rowsPerPage = 10,
}: {
  classroomTeacherId: string;
  page?: number;
  rowsPerPage?: number;
}) => {
  return cookieAuthInstance.get<CoursesResponse>(
    `/classrooms/${classroomTeacherId}/courses/?page=${page}&per_page=${rowsPerPage}`,
  );
};

const classroomUpdate = ({
  classroomId,
  name,
  startDate,
  endDate,
  maxPupils,
}: { classroomId: string } & ClassroomBasicUpdateRequest) => {
  return cookieAuthInstance.patch<ClassroomResponse>(
    `/classrooms/${classroomId}`,
    {
      name,
      startDate,
      endDate,
      maxPupils,
    },
  );
};

const classroomUpdatePupils = ({
  classroomId,
  pupils,
  teachers,
  removePupils,
  removeTeachers,
}: { classroomId: string } & ClassroomMembersUpdateRequest) => {
  return cookieAuthInstance.put<ClassroomResponse>(
    `/classrooms/${classroomId}`,
    {
      pupils,
      teachers,
      removePupils,
      removeTeachers,
    },
  );
};

const classroom = {
  create: classroomCreate,
  get: classroomGet,
  update: classroomUpdate,
  updatePupils: classroomUpdatePupils,
  getCode: classroomGetCode,
  enroll: enrollStudentInClassroom,
  listCourses: courseList,
};

export default classroom;
