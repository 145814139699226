import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Box, Button, Grid, Grid2, Typography } from "@mui/material";
import { setLoading, setSnackbar } from "reducers/ui";
import StudentTable from "components/teacher/StudentTable";
import api from "lib/api";
import StudentChatPreview from "components/teacher/StudentChatPreview";
import { Edit } from "@mui/icons-material";
import SentimentPieChart from "components/teacher/SentimentPieChart";
import ObjectiveBubbleChart from "components/teacher/ObjectiveBubbleChart";
import ActivityDetails from "components/teacher/ActivityDetails";
import { useHistory, useLocation } from "react-router-dom";
import ToggleActivityButton from "components/teacher/ToggleActivityButton";

const ActivityDashboard = (props: any) => {
  const { selectedActivity, currentSchoolId, currentClassroomId } = props;

  const [students, setStudents] = useState<
    Array<{
      id: any;
      name: string;
      firstName: any;
      lastName: any;
      email: any;
      profileImageLink: any;
      notes: string;
      analytics: LatestObjectivesSummaryResponse[] | null;
      sentiment_analytics: CourseActivityObjectiveSentimentAnalytics[];
    }>
  >([]);

  //todo add / remove student notes?
  const [studentNotes, setStudentNotes] = useState<{ [key: string]: string }>(
    {},
  );
  // todo add loading states back in
  // const [refreshing, setRefreshing] = useState(false);
  // const [objectivesProgress, setObjectivesProgress] = useState<Array<any>>([]);
  // const [sentimentsProgress, setSentimentsProgress] = useState<Array<any>>([]);
  // const [objectivesProgressLoading, setObjectivesProgressLoading] = //* changed
  //   useState(false);
  // const [sentimentsProgressLoading, setSentimentsProgressLoading] =
  //   useState(false);
  const studentConversation = useSelector(
    (state: any) => state.studentConversation,
  );
  const tableRef = useRef(null);
  const previewRef = useRef(null);
  const [previewHeight, setPreviewHeight] = useState("");

  useEffect(() => {
    const adjustHeight = () => {
      if (tableRef.current && previewRef.current) {
        setPreviewHeight(
          `${Math.max((tableRef.current as HTMLElement).offsetHeight, 600)}px`,
        );
      }
    };

    adjustHeight();
    // Readjust on window resize
    window.addEventListener("resize", adjustHeight);

    return () => window.removeEventListener("resize", adjustHeight);
  });
  const [studentChatHistory, setStudentChatHistory] = useState<
    Array<CourseActivityPupilChatHistorySimpleDetails>
  >([]);

  useEffect(() => {
    const fetchStudentConversation = async () => {
      const response = await api.activity.getStudentChatHistory(
        {
          activityId: selectedActivity.activity.id,
          studentId: studentConversation.studentConversation.id,
          page: 1,
          perPage: 9999, // todo this is just here to ignore pagination for now
        },
      );

      setStudentChatHistory(response.data.chatHistory.toReversed());
    };

    if (studentConversation?.studentConversation) fetchStudentConversation();
  }, [studentConversation]);

  useEffect(() => {
    const fetchBatchAnalytics = async (students: any[]) => {
      try {
        if (!selectedActivity.activity.id) {
          throw new Error(
            "Unable to fetch activity analytics. Expected an activityId but got none",
          );
        }

        const pupilIds = students.map((student) => student.id);
        const response = await api.activities.getBatchAnalytics(
          {
            activityId: selectedActivity.activity.id,
            pupilIds,
          },
        );

        return response.data.analytics || {};
      } catch (e: any) {
        props.setSnackbar({
          open: true,
          message: e.message,
          severity: "error",
        });
        return {} as { [key: number]: LatestObjectivesSummaryResponse };
      }
    };

    const fetchBatchSentiments = async (
      students: any[],
    ): Promise<{
      [key: number]: CourseActivityObjectiveSentimentAnalyticsResponse;
    }> => {
      try {
        if (!selectedActivity.activity.id) {
          throw new Error(
            "Unable to fetch sentiments. Expected an activityId but got none",
          );
        }

        const pupilIds = students.map((student) => student.id);
        const response = await api.activities.getBatchSentiments(
          {
            activityId: selectedActivity.activity.id,
            pupilIds,
          },
        );

        return response.data.analytics || {};
      } catch (e: any) {
        props.setSnackbar({
          open: true,
          message: e.message,
          severity: "error",
        });
        return {};
      }
    };

    const fetchStudents = async () => {
      if (!students.length) setLoading(true);

      if (props.setObjectivesProgressLoading) {
        setTimeout(() => {
          props.setObjectivesProgressLoading(true);
        }, 50);
      }

      try {
        const response = await api.school.listClassrooms(
          {
            schoolId: currentSchoolId,
            rowsPerPage: 9999,
            pupilsPerPage: 99999,
            teachersPerPage: 99999,
            mine: true,
          },
        );

        // get list of students from the matching classroom
        const classroomStudents =
          response.data.classrooms.find(
            (c: any) => c.classroom.id === currentClassroomId,
          )?.pupils || [];

        // fetch student analytics data if we have an activityId, otherwise return empty objects
        const fetchStudentAnalytics = async () => {
          if (!selectedActivity.activity.id) {
            return {
              analytics: {},
              sentiments: {},
            };
          }

          const [analytics, sentiments] = await Promise.all([
            fetchBatchAnalytics(classroomStudents),
            fetchBatchSentiments(classroomStudents),
          ]);

          return {
            analytics,
            sentiments,
          };
        };

        const { analytics: batchAnalytics, sentiments: batchSentiments } =
          (await fetchStudentAnalytics()) as {
            analytics: AnalyticsResponse;
            sentiments: SentimentsResponse;
          };

        let fetchedStudents = await Promise.all(
          classroomStudents.map(async (student: any) => {
            // Get analytics and sentiments for this student from batch responses
            const analytics =
              selectedActivity.activity.id && student.id in batchAnalytics
                ? [batchAnalytics[student.id]]
                : null;

            const sentiment_analytics =
              selectedActivity.activity.id && student.id in batchSentiments
                ? batchSentiments[student.id].analytics
                : [];

            // Convert student.id to string when accessing studentNotes
            const studentNote = studentNotes[student.id.toString()] || "";

            return {
              id: student.id,
              name: student.firstName + " " + student.lastName,
              firstName: student.firstName,
              lastName: student.lastName,
              email: student.email,
              profileImageLink: student.profileImageLink,
              notes: studentNote,
              analytics,
              sentiment_analytics,
            };
          }) || [],
        );

        setStudents(fetchedStudents);
      } catch (e: any) {
        props.setSnackbar({
          open: true,
          message: e.message,
          severity: "error",
        });
      } finally {
        // delay turning off loading states slightly
        setTimeout(() => {
          if (props.setObjectivesProgressLoading)
            props.setObjectivesProgressLoading(false);
          if (props.setSentimentsProgressLoading)
            props.setSentimentsProgressLoading(false);
          if (props.setRefreshing) props.setRefreshing(false);
        }, 300);
      }
    };
    fetchStudents();
    const intervalId = setInterval(fetchStudents, 30e3);
    return () => clearInterval(intervalId);
  }, []);
  const history = useHistory();
  const location = useLocation();

  return (
    <Grid
      container
      sx={{
        pt: 1,
        px: 1,
        mb: 4,
        gap: 1,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid2
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Typography variant="h4" fontFamily="Inter" fontWeight={"bold"}>
            {selectedActivity.activity.name}
          </Typography>

          <ToggleActivityButton />
        </Box>
        <Button
          color="secondary"
          endIcon={<Edit />}
          onClick={() => {
            history.push(location.pathname + "/edit");
          }}
        >
          <Typography fontFamily="Inter" fontWeight={"bold"}>
            Edit Activity
          </Typography>
        </Button>
      </Grid2>
      <Grid2
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          py: 1,
          px: 1,
          backgroundColor: "white",
          flexWrap: "wrap",
          borderRadius: 4,
          gap: 1.5,
          justifyContent: "space-evenly",
        }}
      >
        <Grid2
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "350px",
          }}
        >
          <Typography fontFamily="Inter" fontWeight={"bold"}>
            Overall Sentiments
          </Typography>
          <SentimentPieChart students={students} />
        </Grid2>
        {selectedActivity.objectives.map((objective: any, i: number) => {
          return (
            <Grid2
              container
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "250px",
              }}
              key={"objective-bubble-chart-" + i}
            >
              <Typography fontFamily="Inter" fontWeight={"bold"}>
                Objective {i + 1} Progress
              </Typography>
              <Typography fontFamily="Inter">"{objective.details}"</Typography>
              <ObjectiveBubbleChart students={students} objective={objective} />
            </Grid2>
          );
        })}
      </Grid2>
      <Grid2
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
        }}
      >
        <Grid2 sx={{ flexGrow: 1 }}>
          <StudentTable
            ref={tableRef}
            activity={selectedActivity}
            students={students}
          />
        </Grid2>

        {studentConversation.studentConversation && (
          <Grid2
            sx={{
              width: "25%",
            }}
            ref={previewRef}
          >
            <StudentChatPreview
              height={previewHeight}
              studentChatHistory={studentChatHistory}
            />
          </Grid2>
        )}
      </Grid2>
      <ActivityDetails activity={selectedActivity} />
    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
  selectedActivity: state.user.selectedActivity,
  currentSchoolId: state.user.currentSchoolId,
  currentSchoolCode: state.user.currentSchoolCode,
  currentClassroomId: state.user.currentClassroomId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
  currentStudentId: state.user.id,
});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityDashboard);
