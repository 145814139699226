import { cookieAuthInstance } from "lib/api/utils";

const inviteCreate = ({
  schoolId,
  email,
  expiresInDays,
}: { schoolId: string } & SchoolInviteTeacherRequest) => {
  return cookieAuthInstance.post<SchoolWithInviteEmailResponse>(
    `/schools/${schoolId}/invite/`,
    {
      email,
      expiresInDays,
    },
  );
};

const inviteList = ({ schoolId }: { schoolId: string }) => {
  return cookieAuthInstance.get<SchoolWithInvitesResponse>(
    `/schools/${schoolId}/invite/`,
  );
};

const invite = {
  create: inviteCreate,
  list: inviteList,
};
export default invite;
