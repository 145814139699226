import React, { forwardRef, ReactNode, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TextField,
  Avatar,
  Button,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import SentimentLabels from "./SentimentLabels";
import findMaxEmotion from "utils/findMaxEmotion";
import { useDispatch } from "react-redux";
import { showStudentConversation } from "actions/studentConversation";

const StudentTable = forwardRef<
  HTMLDivElement,
  {
    students: Array<{
      id: any;
      name: string;
      firstName: any;
      lastName: any;
      email: any;
      profileImageLink: any;
      notes: string;
      analytics: LatestObjectivesSummaryResponse[] | null;
      sentiment_analytics: CourseActivityObjectiveSentimentAnalytics[];
    }>;
    activity: any;
  }
>(({ students, activity }, ref) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredData = students.filter(
    (row) =>
      row.name.toLowerCase().includes(searchQuery) ||
      findMaxEmotion(row).toLowerCase().includes(searchQuery),
  );

  const handleSort = (event: SelectChangeEvent<string>, child: ReactNode) => {
    const selectedOrderBy = event.target.value as string;
    const isAsc = orderBy === selectedOrderBy && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(selectedOrderBy);
  };

  const sortedData = filteredData.sort((a, b) => {
    if (order === "asc")
      return (
        a[orderBy as keyof typeof a]
          ?.toString()
          .localeCompare?.(b[orderBy as keyof typeof b].toString()) || 0
      );
    return (
      b[orderBy as keyof typeof b]
        ?.toString()
        .localeCompare?.(a[orderBy as keyof typeof a].toString()) || 0
    );
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = sortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <Paper
      ref={ref}
      sx={{
        pt: 1,
        px: 1,
        borderRadius: "16px",
        fontFamily: "Inter",
        boxShadow: "none",
        flexGrow: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Search..."
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            },
          }}
          sx={{
            width: "25%",
            height: "44px",
            "& .MuiInputBase-root": { height: "100%" },
            fontFamily: "Inter",
          }}
          onChange={handleSearch}
        />
        <FormControl
          variant="standard"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <span
            style={{ color: "#757575", fontSize: "14px", fontFamily: "Inter" }}
          >
            Sort By:
          </span>
          <Select
            value={orderBy}
            onChange={handleSort}
            sx={{
              fontSize: "14px",
              "& .MuiSelect-select": {
                padding: "2px 8px",
                fontFamily: "Inter",
              },
              border: "none",
            }}
            disableUnderline
          >
            <MenuItem value="name" sx={{ fontFamily: "Inter" }}>
              Name
            </MenuItem>
            <MenuItem value="sentiment" sx={{ fontFamily: "Inter" }}>
              Sentiment
            </MenuItem>
          </Select>
        </FormControl>
      </div>

      <TableContainer>
        <Table sx={{ fontFamily: "Inter" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontFamily: "Inter" }}>Student Name</TableCell>
              <TableCell sx={{ fontFamily: "Inter" }}>Sentiment</TableCell>
              <TableCell sx={{ fontFamily: "Inter" }}>
                Objective Comprehension{" "}
                {activity.objectives
                  .map((el: any, index: number) => `${index + 1}`)
                  .join(" | ")}
              </TableCell>
              <TableCell sx={{ fontFamily: "Inter" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row) => (
              <TableRow key={row.id + "student-table-row"}>
                <TableCell
                  sx={{
                    fontFamily: "Inter",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Avatar alt={row.name} src={""} sx={{ mr: 1 }} />
                  {row.name}
                </TableCell>
                <TableCell sx={{ fontFamily: "Inter" }}>
                  <SentimentLabels label={findMaxEmotion(row)} />
                </TableCell>
                <TableCell sx={{ fontFamily: "Inter" }}>
                  {row.analytics?.[0].objectives_summary.map((summary, i) => (
                    <span
                      key={
                        summary.understanding_level +
                        i +
                        "summary-understanding"
                      }
                      style={{
                        display: "inline-block",
                        minWidth: "80px",
                        textAlign: "center",
                        borderRight:
                          i !==
                          (row.analytics?.[0].objectives_summary.length ?? 0) -
                            1
                            ? "1px solid black"
                            : "none",
                      }}
                    >
                      {summary.understanding_level === "No Comprehension"
                        ? "None"
                        : summary.understanding_level}
                    </span>
                  ))}
                </TableCell>
                <TableCell sx={{ fontFamily: "Inter" }}>
                  <Button
                    variant="text"
                    color="secondary"
                    style={{
                      textDecoration: "underline",
                      textTransform: "none",
                      fontWeight: "bold",
                      padding: 0,
                      fontFamily: "Inter",
                    }}
                    onClick={() => dispatch(showStudentConversation(row))}
                  >
                    See Chat
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ fontFamily: "Inter" }}
      />
    </Paper>
  );
});

export default StudentTable;
